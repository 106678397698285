export const config: AppConfig = window['config']

export interface AppConfig {
    WebApp_idApp: number;
    WebApp_ApiUrl: any;
    plantel: number,
    apiUrl: string,
    webAppUrl: string,
    clientKey: string,
    docPath: string,
    hMedico: string
}