<div class="not-found-container">
    <div class="not-found-content">
      <h1 class="not-found-title">404</h1>
      <h2 class="not-found-subtitle">Página no encontrada</h2>
      <p class="not-found-text">
        ¡Ups! Parece que te perdiste en el camino .
      </p>
      <!-- <img src="assets/not-found-student.png" alt="Estudiante perdido" class="not-found-image"> -->
      <button class="not-found-button" routerLink="/">Regresar al inicio</button>
    </div>
  </div>
  