<div class=" text-right">
    <button class="iberoActionButton rounded" (click)="Evento_ExportarAExcel()" hidden>
        <div class="iberoActionButtonImg">
            <img src="../../../../assets/img/icons/icons8-microsoft-excel-24.png" width="18px" height="18px" />
        </div>
        <span class="iberoActionButtonText">Exportar</span>
    </button>
</div>

<div class="row g-3" style="width: 500px;">
    <div class="col">
        <div class="input-group mb-3">
            <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="Fecha de Inicio" [readonly]="true"
                [(ngModel)]="fechaDeInicio" id="fechaInicio" ngbDatepicker #d="ngbDatepicker">
            <span class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </span>
        </div>
    </div>
    <div class="col">
        <div class="input-group mb-3">
            <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="Fecha Fin" [readonly]="true" id="fechaFin"
                [(ngModel)]="fechaFin" ngbDatepicker #a="ngbDatepicker">
            <span class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="a.toggle()" type="button"></button>
            </span>
        </div>
    </div>

    <div class="paddingTop5px text-center">
        <button class="iberoNormalTextButton rounded">
            <span class="iberoNormalButtonText" (click)="Evento_Buscar()">Buscar</span>
        </button>
    </div>

</div>

<ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="rowData"
    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)">
</ag-grid-angular>