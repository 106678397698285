import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptureModule } from 'ngx-capture';

import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import {
  lasSave, lasEllipsisH,
  lasUsers, lasUserShield, lasUserCog, lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
  lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
  lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher, lasBriefcase, lasEnvelope, lasAddressBook,
  lasMoneyCheckAlt, lasQuestion, lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking,
  lasTools, lasUser, lasEdit, lasBook, lasCalendar, lasArrowLeft, lasUsersCog, lasSearch, lasFileExcel
} from 'angular-line-awesome/icons';

import { UsuarioComponent } from './usuario/usuario.component';
import { RolComponent } from './rol/rol.component';
import { EspacioComponent } from './espacio/espacio.component';
import { EquipoComponent } from './equipo/equipo.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { DiaInhabilComponent } from './diainhabil/diainhabil.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EspaciotipoComponent } from './espaciotipo/espaciotipo.component';
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import { UsuarioTipoComponent } from './usuario-tipo/usuario-tipo.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgGridModule } from 'ag-grid-angular';
import { ReporteComponent } from './reporte/reporte.component';


@NgModule({
  declarations: [UsuarioComponent, RolComponent, EspacioComponent, EquipoComponent,
    SolicitudComponent, EspaciotipoComponent, MenuAdminComponent, DiaInhabilComponent,
    UsuarioTipoComponent,
    ReporteComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TagInputModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AngularLineawesomeModule,
    NgxQRCodeModule,
    NgxCaptureModule,
    NgxPaginationModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AgGridModule.withComponents([])
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }]
})
export class AdminModule {
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSave, lasEllipsisH,
      lasUsers, lasUserShield, lasUserCog,
      lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
      lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
      lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher,
      lasBriefcase, lasEnvelope, lasAddressBook,
      lasMoneyCheckAlt, lasQuestion, lasShieldAlt,
      lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasTools,
      lasUser, lasUserShield, lasEdit, lasBook, lasCalendar, lasArrowLeft, lasUsersCog, lasSearch, lasFileExcel
    ]);
  }
}
