import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import Swal from 'sweetalert2';

export interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-espacio',
  templateUrl: './espacio.component.html',
  styles: [
  ]
})
export class EspacioComponent implements OnInit {
  forma: FormGroup;
  model;

  isSaving:boolean = false;

  p: number = 1;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private equipoService: EquipoService,
    private espacioService: EspacioService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addEquipo();
    //this.addLista();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idEspacio: [0],
      nombre: ['', [Validators.required]],      
      estatus: [true, [Validators.required]],
      capacidad: [0, [Validators.required, Validators.min(1), Validators.max(99)]],
      maximo: [0, [Validators.required, Validators.min(1), Validators.max(24)]],
      equipos: [''],
      equipo: [''],
      ubicacion: ['', [Validators.required]],
      autorizacion:[true, [Validators.required]],
      aviso:[true, [Validators.required]],
      horario:[''],
      horaInicio:['', [Validators.required]],
      horaFin:['', [Validators.required]]
    })
    this.model = this.forma;
  }

  Espacio;
  EspacioLista;
  addLista(){
    this.spinner.show();
    this.espacioService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      console.log(data);
      this.Espacio = data;
      this.EspacioLista = this.Espacio;
      
      for(let i=0; i<this.EspacioLista.length;i++){
        var arrLista = this.EquipoLista
          .filter(x => this.EspacioLista[i].equipos.split(',').includes(x.idEquipo.toString()))
            .map(function(item) { return item.nombre;} );
        this.EspacioLista[i]['eLista'] = arrLista.join(', ');;
      }
    });
  }

  Equipo;
  EquipoLista;
  addEquipo(){
    this.spinner.show();
    this.equipoService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Equipo = data;
      //display: 'Lámpara', value: 1
      //Mostaramos solo los disponibles
      for(let i=0; i<this.Equipo.length;i++){
        this.Equipo[i]['display'] = this.Equipo[i].nombre;
        this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
      }
      this.EquipoLista = this.Equipo;
      this.EquipoLista = this.EquipoLista.filter(x => x.estatus == true);
      this.addLista();
      this.seHorario();
    });
  }

  nuevo(content) {

    this.iniciarForm();
    //this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }
  horario= {'inicio':'06:00', 'fin':'23:00'};
  HoraInicioLista=[];
  HoraFinLista=[];
  seHorario(){
    const hi = Number(this.horario.inicio.split(":")[0])*1;
    const hf = Number(this.horario.fin.split(":")[0])*1;
    var z="";
    for(var i=hi;i<=hf;i++){
        z=i+":00";
        if(i<10) z="0"+i+":00";
        this.HoraInicioLista.push(z);
    }
    //console.log(this.HoraInicioLista);
    //this.HoraFinLista = this.HoraInicioLista; 
  }
  setHoraFin(){
    console.log(this.model.horaInicio);
    var idx = this.HoraInicioLista.findIndex(x => x == this.model.horaInicio)
    this.HoraFinLista = this.HoraInicioLista.slice(idx+1,this.HoraInicioLista.length);
  }  

  oTmp;
  editar(content, modelo) {
    if(modelo.equipos!=null && modelo.equipos!=""){
      let arrEquipo = modelo.equipos.split(',');
      modelo["equipo"] = this.EquipoLista.filter(x => arrEquipo.includes(x.idEquipo.toString()));
    }
    this.model = modelo;
    this.oTmp = { ...modelo }; 

    let hora = JSON.parse(modelo.horario);
    this.model.horaInicio = hora.inicio;

    var idx = this.HoraInicioLista.findIndex(x => x == this.model.horaInicio);
    
    this.HoraFinLista = this.HoraInicioLista.slice(idx+1,this.HoraInicioLista.length);

    this.model.horaFin = hora.fin;
    //this.modal.open(content,{size:'xl'});
    //this.oTmp = { ...modelo }; 
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      var idx = this.EspacioLista.findIndex(x => x.idEspacio == this.oTmp.idEspacio); 
      this.EspacioLista[idx] = this.oTmp;       
      return true;  
    }});  
  }

  guardar() {   
    this.isSaving = true;
    //this.forma.controls["equipo"].setValue("");
    let horario = '{"inicio":"'+this.model.horaInicio+'", "fin":"'+this.model.horaFin+'"}'
    //this.model.horaInicio = hora.inicio;
    //this.model.horaFin = hora.fin;
    this.forma.controls["horario"].setValue(horario);
   
    let equipo = this.forma.controls["equipo"].value;
    
    if(equipo == undefined){
      this.isSaving = false;
      this.MensajeAdvertencia('Seleccione al menos un equipo');
      return;
    }
    let oArr = [];
    for (let i = 0; i < equipo.length; ++i) {
      oArr.push(equipo[i].idEquipo);
    }

    this.forma.controls["equipos"].setValue(oArr.toString());
    if (this.forma.controls["idEspacio"].value == undefined) {
      this.espacioService.addData(this.forma.value).subscribe(
        (res) => {this.recargar(); this.isSaving=false;},        
        (error) => {console.log(error);this.isSaving=false;});
    }
    else {
      this.espacioService.updData(this.forma.controls["idEspacio"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.isSaving=false;},
          (error) => {console.log(error);this.isSaving=false;});
    }
    
    
  }
  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  lfCapacidad(){
    if(this.model.capacidad%2>0){
      this.model.capacidad = Math.ceil(this.model.capacidad);
    }
  }

  lfMaximo(){
    if(this.model.maximo%2>0){
      this.model.maximo = Math.ceil(this.model.maximo);
    }
  }
  

  getObject(obj){
    let jo = JSON.parse(obj);
    return jo.inicio + " - " + jo.fin;
    //console.log(obj);
    //console.log(JSON.parse(obj));
    //return JSON.parse(obj);
  }

  MensajeAdvertencia = (Mensaje) => {
  
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 6000,
      // timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "warning",
      title: `${Mensaje}`,
    });
  
  };
}


