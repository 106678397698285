import { Component, Inject } from '@angular/core';
//import { LoginService } from './core/services/login.service';
import { LoginService } from './data-access/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import * as $ from 'jquery';
import { AuthService } from './data-access/auth.service';
import { config } from './data-access/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ClientApp';
  validar = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    public ls: LoginService,
    private router: Router,
    private location: Location
  ) {
    if(!location.path().includes('inicio') && !authService.isAuthenticated) {
      document.location.href = config.webAppUrl
    }
    localStorage.setItem('valido', '1');
  }

  ngOnInit() {
    /*
    this.router.url;
    this.location.path;
    alert(this.router.url);
    alert(this.location.path);
    
    let path = this.location.path();
    if(path.indexOf('/e-consulta/')==0){
      localStorage.setItem('valido', '1');
    }
    let valor = localStorage.getItem('valido');

    if (valor == '1') {
      this.validar = true;
    }
    */

  }

}