import { Component, OnInit, Input } from '@angular/core';
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import { Observable } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';

export interface AutoCompleteModel {
  value: any;
  display: string;
}
interface Evento extends CalendarEvent {
  tipo: number;
  espacio: number;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styles: [
  ]
})
export class SolicitudComponent implements OnInit {
  forma: FormGroup;
  model;
  NombreCompleto;
  CalMinDate;
  p: number = 1;

  EspacioD = undefined;
  FechaD = undefined;
  HorarioD = undefined;
  HorarioFinD = undefined;
  UsuarioSeleccionado: string = "";
  //Cuenta=undefined;
  User=undefined;

  datePickerJson = {};
  markDisabled;
  json = {
    disable: [6, 7],
    disabledDates: [
      { year: 2020, month: 8, day: 13 },
      { year: 2020, month: 8, day: 19 },
      { year: 2020, month: 8, day: 25 }
    ]
  };

  hCancelar;
  hConfirmar;
  bEdicion = false;
  EquiposDisponibles = [];
  fechaDeInicio: Date;
  fechaFin: Date;
  Estatus: number = 0;
  EsPrimeraVezQueIngresaElUsuario: boolean = true;
  fechaSeleccionada: boolean = false; // Indica si se ha seleccionado una fecha


  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private espacioService: EspacioService,
    private reservacionService: ReservacionService,
    private equipoService: EquipoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: NgbModal,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    const current = new Date();
    this.CalMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.User = this.usuarioService.getUser();
    //console.log(user);
    //this.Cuenta = user.cuenta;
    //this.NombreCompleto = user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    this.iniciarForm();
  }

  ngOnInit(): void {
    //this.addSeccion();
    this.disableDays();
    this.addEspacioTipo();
    //this.addEspacio();
    this.addEquipo();
    this.addLista();
    this.addUsuario();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idReservacion: [0],
      idEspacioTipo: [0],
      idEspacio: [0],
      idUsuario: [0],
      cuenta: [''],
      dia: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      horaFin: ['', [Validators.required]],
      inicio: [''],
      fin: [''],
      idEstatus: [0],
      equipos: [''],
      equipo: [''],
      comentarios: ['1'],
      //hora: ['', [Validators.required]],
      //duracion: [0, [Validators.required]],
      //fechaFin: [''],
      //activo: [true]
    })
    this.model = this.forma;
    this.UsuarioSeleccionado = "";
  }

  Usuario;
  UsuarioLista;
  addUsuario() {
    this.spinner.show();
    this.usuarioService.getUsuarioListadoSistema().toPromise()
      .then(data => {
        this.Usuario = data;
        /*
        this.Usuario.forEach(i => {
          i.nombreCompleto = i.nombre + ' ' + i.apPat + ' ' + i.apMat;
          if (i.roles != '' && i.roles != undefined) {
            var arrLista = this.RolLista
              .filter(x => i.roles.split(',').includes(x.idRol.toString()))
              .map(function (item) { return item.nombre; });
            i['eLista'] = arrLista.join(', ');
          }
        });
        */
        //Asignamos el universo de items al listado, mediante una entidad de filtro
        this.UsuarioLista = this.Usuario;
        console.log(this.UsuarioLista);
        this.spinner.hide();
      })
  }  

  EspacioTipo;
  EspacioTipoLista;
  addEspacioTipo() {
    this.spinner.show();
    this.espacioTipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.EspacioTipo = data;
        this.EspacioTipoLista = this.EspacioTipo;
        this.addEspacio();
      });
  }

  Espacio;
  EspacioLista;
  addEspacio() {
    this.spinner.show();
    this.espacioService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        //this.addLista();
        this.Espacio = data;
        this.EspacioLista = this.Espacio;

      });
  }

  Equipo;
  EquipoLista;
  addEquipo() {
    this.spinner.show();
    this.equipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Equipo = data;
        //display: 'Lámpara', value: 1
        for (let i = 0; i < this.Equipo.length; i++) {
          this.Equipo[i]['display'] = this.Equipo[i].nombre;
          this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
        }
        this.EquipoLista = this.Equipo;
        //this.addLista();
      });
  }

  Reservacion;
  ReservacionLista;
  events: Evento[];
  addLista() {

    this.ObtenerInformacionDeReservaciones(null, null, -1);
  }

  Evento_Filtrar() {
    if (this.fechaDeInicio == null || this.fechaFin == null) {
      this.toastr.error("Las fechas ingresadas no son válidas.");
      return;
    }


    if (this.fechaDeInicio > this.fechaFin) {
      this.toastr.error("La fecha de inicio debe ser menor o igual a la fecha fin.");
      return;
    }
    this.ObtenerInformacionDeReservaciones(this.fechaDeInicio, this.fechaFin, this.Estatus);

  }

  DebemosRegresarTodosLosElementos(fechaInicio, fechaFin, idEstatus) {
    return fechaInicio == null && fechaFin == null && idEstatus == 0;
  }

  ObtenerInformacionDeReservaciones(fechaInicio, fechaFin, idEstatus) {


    this.spinner.show();

    let reservationSearchMethod: Observable<any> = null;

    reservationSearchMethod = this.reservacionService.ObtenerReservacionesPorFiltro(fechaInicio, fechaFin, idEstatus);


    reservationSearchMethod.toPromise()
      .then(data => {
        this.spinner.hide();

        this.Reservacion = data;

        this.ReservacionLista = this.Reservacion.filter(x => x.autorizacion);
        console.log(this.ReservacionLista);
        /*let fecha;
        let d1;
        let d2;
        for (let i = 0; i < this.ReservacionLista.length; i++) {
          this.ReservacionLista[i]['horaInicio'] = this.ReservacionLista[i].inicio.split('T')[1].substring(0, 5);
          this.ReservacionLista[i]['horaFin'] = this.ReservacionLista[i].fin.split('T')[1].substring(0, 5);

          fecha = this.ReservacionLista[i].fin;

          d1 = new Date();
          d2 = new Date(fecha);

          this.ReservacionLista[i]["finalizada"] = false;
          if (d1.getTime() > d2.getTime()) {
            this.ReservacionLista[i]["finalizada"] = true;
            this.ReservacionLista[i].estatus = 'Realizada';
          }
        }*/



        this.events = this.ReservacionLista.map(x => ({
          //idReservacion: x.idReservacion,
          tipo: x.espacioTipo,
          espacio: x.espacio,
          start: x.inicio,
          end: x.fin,
          title: x.espacio,
          color: colors.blue
        }));

        //this.ReservacionLista = this.Reservacion;

      });
  }

  Filtro;
  filtraTipo() {
    let Filtro = this.Espacio;

    //idNivel
    if (this.model.idEspacioTipo != 0 && this.model.idEspacioTipo !== undefined) {
      Filtro = Filtro
        .filter(x => x.idEspacioTipo === Number(this.model.idEspacioTipo));
    }


    this.EspacioLista = Filtro;

  }

  HoraInicioLista;
  HoraFinLista;
  setEspacio() {

    //Obtenemos el espacio para establecer las reglas de operación
    let et = this.Espacio.find(x => x.idEspacio == Number(this.model.idEspacio));
    //Obtenemos la lista de equipos que permite el espacio
    let arrEquipo = et.equipos.split(',');
    this.EquipoLista = this.Equipo.filter(x => arrEquipo.includes(x.idEquipo.toString()));
    //Asignamos la lista de equipos seleccionados por el usuario en el formato requerido

    if (this.model.equipos != '' && this.model.equipos != undefined && !Array.isArray(this.model.equipos)) {
      let arrSel = this.model.equipos.split(',');
      this.model.equipos = this.EquipoLista.filter(x => arrSel.includes(x.idEquipo.toString()));
    }



    // let hora = JSON.parse(et.horario);
    // let inicio = Number(hora.inicio.split(':')[0]);
    // let fin = Number(hora.fin.split(':')[0]);
    // //horarios
    // let inicioLista = [];
    // for (let i = inicio; i <= fin; i++) {
    //   let h = (i < 10 ? '0' + i : i)
    //   inicioLista.push({ horaInicio: h + ':00' });
    // }
    // this.HoraInicioLista = inicioLista;
    // this.HoraFinLista = inicioLista;
  }


  setEquipos() {
    if (this.model.horaFin != '' && this.model.horaFin !== undefined) {
      const dia = this.forma.controls["dia"].value;
      const diaSel = this.formatDate(dia);
      const horaInicio = this.forma.controls["horaInicio"].value;
      const horaFin = this.forma.controls["horaFin"].value;
      this.spinner.show();
      this.equipoService.equiposDisponibles({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio, HorarioFin: horaFin }).subscribe(
        res => {
          this.EquiposDisponibles = res.map(x => { x.cant = 0; return x });
          this.slideDownEquipos();
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    } else {
      this.slideUpEquipos();
    }
  }



  minusEquipo(item) {
    if (item.cant > 0) {
      item.cant--;
    }
  }

  plusEquipo(item) {
    if (item.cant < item.cantidad) {
      item.cant++;
    }
  }

  setHoraFin() {
    this.model.horaFin = '';
    this.HorarioFinD = true;
    this.slideUpEquipos();
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.forma.controls["dia"].value;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.forma.controls["horaInicio"].value;

      this.spinner.show();
      this.reservacionService.getHorariosFin({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.bEdicion = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }
  }

  slideDownEquipos() {
    $('#equipoContent').slideDown({
      start: () => {
        $('#equipoContent').css({ display: 'flex' });
      }
    });
  }

  slideUpEquipos() {
    $('#equipoContent').slideUp(400, () => {
      this.EquiposDisponibles = [];
    });
  }

  nuevo(content) {
    this.bEdicion = false;
    this.iniciarForm();
    this.model.cuenta = this.User.cuenta;
    this.model.nombre = this.User.nombre;
    this.model.apPat = this.User.apPat;
    this.model.apMat = this.User.apMat;
    //this.NombreCompleto = user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    //this.model.cuenta = this.Cuenta;
    this.model.activo = true;
    this.modal.open(content, { size: 'xl' });

  }


  editar(content, modelo) {
    this.seHorario();
    //this.HoraInicioLista = [modelo.horaInicio];
    //this.HoraFinLista = [modelo.horaFin];
    console.log(modelo);

    this.model = modelo;
    this.model.horaInicio = this.model.inicio.split("T")[1].substr(0, 5);
    this.model.horaFin = this.model.fin.split("T")[1].substr(0, 5);
    this.restoreEspacioLista();
    this.hCancelar = false;
    this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.modal.open(content, { size: 'xl' });
    for (let e of modelo.equipos) {
      e.cant = e.cantidad;
    }

  }
  horario= {'inicio':'06:00', 'fin':'23:00'};
  //HoraInicioLista=[];
  //HoraFinLista=[];
  seHorario(){
    this.HoraInicioLista = []
    const hi = Number(this.horario.inicio.split(":")[0])*1;
    const hf = Number(this.horario.fin.split(":")[0])*1;
    var z="";
    for(var i=hi;i<=hf;i++){
        z=i+":00";
        if(i<10) z="0"+i+":00";
        this.HoraInicioLista.push(z);
    }
    //console.log(this.HoraInicioLista);
    this.HoraFinLista = this.HoraInicioLista; 
  }

  restoreEspacioLista() {
    this.EspacioLista = this.Espacio;
  }


  formatDate(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),

    ].join('-');
  }

  uf_Date(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  Evento_Buscar() {

    let cuentaABuscar = this.forma.controls["cuenta"].value;
    if (cuentaABuscar == undefined) {
      this.toastr.error("Cuenta no válida.");
      return;
    }

    this.usuarioService.ObtenerInformacionAdicionalDelUsuario(this.forma.controls["cuenta"].value, 24)
      .toPromise().then(result => {
        if (result == null) {
          this.toastr.error("No se pudo encontrar el usuario.");
        } else {
          let resultado: any = result;
          this.UsuarioSeleccionado = resultado.nombre + ' ' + resultado.apPat + ' ' + resultado.apMat;
          console.log(resultado);
        }
      });



  }

  guardar(idEstatus) {

    this.spinner.show();
    var dia = this.forma.controls["dia"].value;
    var hinicio = this.forma.controls["horaInicio"].value;
    var hfin = this.forma.controls["horaFin"].value;
    var idEspacio = this.forma.controls["idEspacio"].value;
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + 'T' + hinicio + ':00';
    var fin = dInicio + 'T' + hfin + ':00';
    this.forma.controls["inicio"].setValue(inicio);
    this.forma.controls["fin"].setValue(fin);

    //this.usuarioService.ObtenerInformacionAdicionalDelUsuario(this.forma.controls["cuenta"].value, 24).toPromise().then(result => {

    //    let resultado: any = result;
    //    this.forma.controls["idUsuario"].setValue(resultado.idUsuario);

        //Si no es una cancelación, determinamos el estatus según el espacio
        if (idEstatus != 3) {
          let espacio = this.Espacio.find(x => x.idEspacio === idEspacio);
          if (espacio.autorizacion) { idEstatus = 1; }
        }
        this.forma.controls["idEstatus"].setValue(idEstatus);

        let arr = [];
        for (let e of this.EquiposDisponibles) {
          if (e.cant > 0) {
            e.cantidad = e.cant;
            arr.push(e);
          }
        }
        this.forma.controls["equipos"].setValue(arr);

        if (this.forma.controls["idReservacion"].value == undefined) {
          this.reservacionService.addData(this.forma.value).subscribe(
            res => {
              this.recargar();
              this.UsuarioSeleccionado = "";
              this.spinner.hide();
              this.toastr.success("La información ha sido enviada");
            },
            error => {
              if (typeof error.error === 'object') {
                this.toastr.error(error.message);
              } else {
                this.toastr.error(error.error);
              }
              this.spinner.hide();
            });
        }
        else {
          this.reservacionService.updData(this.forma.controls["idReservacion"].value, this.forma.value).subscribe(
            (res) => { this.toastr.success("La información ha sido actualizada"); this.UsuarioSeleccionado = ""; this.recargar(); this.spinner.hide(); },
            (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
        }

      //});


  }

  autorizar() {
    this.spinner.show();
    this.reservacionService.autorizarReservacion(this.model.idReservacion).subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success("Autorización realizada"); this.recargar();
      },
      error => {
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );
  }

  cancelar() {
    Swal.fire({
      title: 'Cancelar Reservación',
      input: 'textarea',
      text: "Se cancelará la reservación. Déjanos un comentario con el motivo de la cancelación.",
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Cancelar Reservación',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {
        this.spinner.show();
        this.reservacionService.cancelarReservacion({ IdReservacion: this.model.idReservacion, MotivoCancelacion: comentario }).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success("La reservación ha sido cancelada"); this.recargar();
          },
          error => {
            if (typeof error.error === 'object') {
              this.toastr.error(error.message);
            } else {
              this.toastr.error(error.error);
            }
            this.spinner.hide();
          }
        );
        return true;
      }
    });
  }

  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  util_fdt(fecha) {
    var d = new Date(fecha);
    return d.toLocaleString();
  }

  setHorario() {
    this.fechaSeleccionada = true;
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.slideUpEquipos();
    var dia = this.forma.controls["dia"].value;
    var diaSel = this.formatDate(dia);
    this.spinner.show();
    this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel }).subscribe(
      res => {
        this.HoraInicioLista = res;
        this.HorarioD = undefined;
        this.spinner.hide();
      },
      error => {
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );
  }

  isDisabled;
  disableDays() {
    //to disable specific date and specific weekdays
    this.isDisabled = (
      date: NgbDateStruct
      //current: { day: number; month: number; year: number }
    ) => {
      return this.json.disabledDates.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
        || (this.json.disable.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
      )
        ? true
        : false;
    };
  }

  minDate: Date = new Date();
  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }

}





