<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="text-center pt-3">
    <h4 class="title-section">Datos del usuario</h4>
  </div>

  <!--Formulario-->
  <form [formGroup]="fprincipal">
    <div class="row text-dark">
      <input class="form-control" type="hidden" formControlName="idUsuario"
             [(ngModel)]="mprincipal.idUsuario">
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-6">
        <p>&nbsp;</p>
      </div>
      <div class="form-group col-lg-6">
        <p>&nbsp;</p>
      </div>
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-4">
        <label for="nivel">Clave Departamento:</label>
        <input class="form-control" type="text" formControlName="nivel" [readonly]="true"
               [(ngModel)]="mprincipal.nivel">
      </div>
      <div class="form-group col-lg-4">
        <label for="departamento">Departamento:</label>
        <input class="form-control" type="text" formControlName="departamento" [readonly]="true"
               [(ngModel)]="mprincipal.departamento">
      </div>
      <div class="form-group col-lg-4">

        <label for="email">Correo electrónico:</label>
        <input class="form-control" type="text" formControlName="email" [readonly]="true"
               [(ngModel)]="mprincipal.email">
      </div>
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-4">
        <label for="nombre">Nombre:</label>
        <input class="form-control" type="text" formControlName="nombre" [readonly]="true"
               [(ngModel)]="mprincipal.nombre">
      </div>
      <div class="form-group col-lg-4">
        <label for="apPat">Apellido Paterno:</label>
        <input class="form-control" type="text" formControlName="apPat" [readonly]="true"
               [(ngModel)]="mprincipal.apPat">
      </div>
      <div class="form-group col-lg-4">
        <label for="apMat">Apellido Materno:</label>
        <input class="form-control" type="text" formControlName="apMat" [readonly]="true"
               [(ngModel)]="mprincipal.apMat">
      </div>
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-4">
        &nbsp;
      </div>

      <div class="form-group col-lg-4">
        &nbsp;
      </div>

      <div class="form-group col-lg-4">
        &nbsp;
      </div>
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-12">
        &nbsp;
      </div>
    </div>

    <div class="row text-dark pt-3">
      &nbsp;
    </div>
  </form>


</div>
