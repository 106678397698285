import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-espaciotipo',
  templateUrl: './espaciotipo.component.html'
})
export class EspaciotipoComponent implements OnInit {
  forma: FormGroup;
  model;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
    ) {
      if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
       this.iniciarForm();
      }

  ngOnInit(): void {
    this.addLista();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idEspacioTipo: [0],
      nombre: ['', [Validators.required]],
      estatus: [true, [Validators.required]]
    })
    this.model = this.forma;
  }
  
  EspacioTipo;
  EspacioTipoLista;
  addLista(){
    this.spinner.show();
    this.espacioTipoService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.EspacioTipoLista = data;
      //console.log(data);
    });
  }
  
  nuevo(content) {
    this.iniciarForm();
    //this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }
  
  guardar() {
    //console.log(this.forma.value);
    if (this.forma.controls["idEspacioTipo"].value == undefined) {
      this.espacioTipoService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.espacioTipoService.updData(this.forma.controls["idEspacioTipo"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
          (error) => {console.log(error);});
    }
    
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

}
