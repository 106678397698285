import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class TipoUsuarioService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getData() {
    return this.http.get(config.apiUrl+'/TipoUsuario');
  }

  //Cambio
  updData(id:undefined, data:undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/TipoUsuario/' + id, data);
  } 
    

}
