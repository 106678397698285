import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { config } from './config';

@Injectable()
export class AuthService {

  private helper = new JwtHelperService()
  private timer: any

  constructor(
    private storage: Storage = localStorage
  ) { }

  private get token(): string {
    const userJson = this.storage.getItem('user')
    const user: {token: string} = JSON.parse(userJson)
    return user.token
  }

  get isAuthenticated(): boolean {
    try {
      const isExpired = this.helper.isTokenExpired(this.token);
      this.verifyExpirationTime()
      return !isExpired
    } catch {
      return false
    }
  }

  verifyExpirationTime() {
    if(this.timer) { return }
    this.timer = setInterval( _ => {
      if(this.helper.isTokenExpired(this.token)){
          localStorage.setItem('valido', '0');
          localStorage.setItem('user', '');
          window.location.href = config.webAppUrl
      }
    }, 1000)
  }
}
