import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class EspacioTipoService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getData() {
    //return this.http.get('assets/json/equipo.json');
    return this.http.get(config.apiUrl+'/EspacioTipo/');
  }

  //Alta
  addData(data:undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/EspacioTipo/', data);
  }

  //Cambio
  updData(id:undefined, data:undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/EspacioTipo/' + id, data);
  } 

  //porusuario
  getDatabyUser(data): Observable<any> {
    return this.http.post(config.apiUrl + '/EspacioTipo/Usuario/', data);
  }

}
