import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { GrupoService } from 'src/app/data-access/grupo.service';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { TipoUsuarioService } from 'src/app/data-access/tipoUsuario.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styles: [
  ]
})
export class MenuComponent implements OnInit {

  private nombreCompletoCancellable?: Subscription

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private tipoUsuarioService: TipoUsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    // let isAdmin = this.usuarioService.adm();
    // if(isAdmin){
    //   this.router.navigate(['/menu-admin'])
    // } 

  }

  selectedUser = 2;
  isSelected = false;
  onSelect(index): void {
    this.selectedUser = index;
  }

  //UsuariosLista;
  //GrupoLista;
  NombreCompleto;
  Usuario;
  ngOnInit(): void {

    this.usuarioService.nombreCompleto$.subscribe(val => {
      this.NombreCompleto = val;
    });

    this.selectedUser = 2;
    //Validamos si hay algún grupo seleccionado
    let user = localStorage.getItem('usuarioGrupo');
    //console.log(user);
    if (user != undefined) {
      this.selectedUser = Number(user);
    }

 
    this.getGrupoLista();

  }

  ngOnDestroy() {
    this.nombreCompletoCancellable?.unsubscribe()
  }

  GrupoLista;
  Grupo;
  getGrupoLista() {
    this.spinner.show();
    /*
    this.GrupoLista = [
    {estatus: true,  icono: "calendar", idGrupo: 1, idPerfil: 1, nombre: "Calendario"},
    {estatus: true,  icono: "address-book", idGrupo: 2, idPerfil: 1, nombre: "Reservaciones"},
    {estatus: true,  icono: "book", idGrupo: 3, idPerfil: 1, nombre: "Histórico"},
    {estatus: true,  icono: "user", idGrupo: 3, idPerfil: 1, nombre: "Perfil Usuario"}
    ];

    this.spinner.hide();
    */
    this.grupoService.getGrupo().toPromise()
      .then(data => {
        this.Grupo = data;
        console.log(this.Grupo);
        this.GrupoLista = this.Grupo.filter(x => x.idPerfil === 1);
        this.spinner.hide();
        //alert(2);
        console.log(this.GrupoLista);
        //this.getTipoUsuario();
      });

  }
  /*
    UsuariosLista;
    Usuarios;
    getTipoUsuario()
    {
      this.spinner.show();
      this.tipoUsuarioService.getData().toPromise()
        .then(data => {
          this.Usuarios = data;
          //console.log(this.Usuarios)
          this.UsuariosLista = this.Usuarios.filter(x => x.planteles.includes(this.sitio));
          const unique = [...new Set(this.UsuariosLista.map(item => item.idGrupo))];
          //console.log(unique);
          this.GrupoLista = this.GrupoLista.filter(i => unique.includes(i.idGrupo));
  
          if(this.sitio==3){
            this.GrupoLista.push({estatus: true,
              icono: "book-open",
              idGrupo: 5,
              nombre: "Manual"})
          }
  
          console.log(this.GrupoLista)
          this.spinner.hide();
        })
    }  
  */
  UsuariosFiltrados;
  idGrupo;
  grupoDesc;
  @ViewChild('manual') manual: TemplateRef<any>;
  filtra(content, idGrupo) {
    let ruta = '';
    this.grupoDesc = this.GrupoLista.find(x => Number(x.idGrupo) === Number(this.idGrupo));
    switch (idGrupo) {
      case 1: ruta = 'perfil-usuario'; break;
      case 2: ruta = 'e-reservacion'; break;
      case 3: ruta = 'e-calendario'; break;
      case 4: ruta = 'e-historico'; break;
      default: ruta = '';
    }

    if (ruta == 'cuestionario') {
      window.open('https://cuestionariodesalud.ibero.mx/#/');
    }
    else {
      this.router.navigate(['/' + ruta]);
    }
    /*
    //Si es el manual, solo abrimos una nueva ventana
    if(idGrupo==5){
      //alert("manual");
      this.modal.open(this.manual, { size: 'xl' });
      return false;
    }

    this.idGrupo = idGrupo;
    this.grupoDesc = this.GrupoLista.find(x => Number(x.idGrupo) === Number(this.idGrupo)).descripcion;
    this.UsuariosFiltrados = this.UsuariosLista.filter(x => Number(x.idGrupo) === Number(this.idGrupo));

    //Si es personal o alumnado, ponemos estos grupos al inicio de los elementos
    if(idGrupo==1 || idGrupo==2){
      let first = 0;
      let data = this.UsuariosFiltrados;
      if(idGrupo==1) first = 24;
      if(idGrupo==2){
        first = 21;
        if(this.sitio==2) first = 34;
      } 
      

      data.unshift(data.splice(data.findIndex(item => item.idTipoUsuario === first), 1)[0])
      this.UsuariosFiltrados = data;      
    }
    this.rows();
    this.modal.open(content, { size: 'xl' });
    */
  }

  actRows;
  rows() {
    this.actRows = [];
    this.UsuariosFiltrados.forEach((item, index) => {
      if (index % 5 == 0) {
        let row = [];
        row.push(item);
        this.actRows.push(row);
      } else {
        this.actRows[this.actRows.length - 1].push(item);
      }
    });
  }

  //filtra(id){}
  //onSelect(id){}



  login(tipo) {
    //alert(tipo);
    this.modal.dismissAll();
    localStorage.setItem('usuarioGrupo', this.selectedUser.toString());
    localStorage.setItem('usuarioTipo', tipo);
    localStorage.setItem('usuarioSitio', this.sitio);
    localStorage.setItem('noredirect', '');
    if (tipo == 104 || tipo == 105 || tipo == 110) {
      this.router.navigate(['/guardia']);
    }
    else {
      this.router.navigate(['/login']);
    }

  }

  sitio;
  logo;
  bgapp;
  bgtext;
  getSitio(sitio) {
    //console.log();
    this.sitio = sitio;
    if (sitio == undefined) {
      this.sitio = this.usuarioService.getSitio();
    }

    this.bgapp = '';
    if (this.sitio == 1) { //1 - CDMX
      this.logo = 'ibero-090.svg';
      this.bgapp = 'bg-ibero-rojo';
      this.bgtext = '';
    }
    if (this.sitio == 2) { //2 - PREPA
      this.logo = 'logo-prepa.svg';
      this.bgapp = 'bg-ibero-rojo';
      this.bgtext = '';
    }
    if (this.sitio == 3) { //3 - TIJUANA
      this.logo = 'ibero-099.svg';
      this.bgapp = '';
      this.bgtext = 'text-ibero';
    }
  }

}
