import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import { DiaInhabilService } from 'src/app/data-access/diainhabil.service';
import * as util from 'src/app/data-access/util';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
import Swal from 'sweetalert2';

import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ControlContainer } from '@angular/forms';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { getDefaultCompilerOptions } from 'typescript';
import { Router } from '@angular/router';

export interface AutoCompleteModel {
  value: any;
  display: string;
}

interface Evento extends CalendarEvent {
  idEspacioTipo: number;
  idEspacio: number;
  idUsuario: number;
  tipo: number;
  espacio: number;
}

@Component({
  selector: 'app-e-calendario',
  templateUrl: './e-calendario.component.html',
  styles: [
  ]
})

//https://stackoverflow.com/questions/51972540/angular-calendar-how-to-change-language

export class ECalendarioComponent {
  forma: FormGroup;
  model;
  NombreCompleto;

  EspacioD = undefined;
  FechaD = undefined;
  HorarioD = undefined;
  HorarioFinD = undefined;

  daySelected;

  CalMinDate;
  datePickerJson = {};
  markDisabled;

  fechaSeleccionada: boolean = false; // Indica si se ha seleccionado una fecha


  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  locale: string = "es";
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  Usuario;
  constructor(
    public fb: FormBuilder,
    private modal: NgbModal,
    private diaInhabilService: DiaInhabilService,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private espacioService: EspacioService,
    private equipoService: EquipoService,
    private reservacionService: ReservacionService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private  router:Router
  ) {
    this.Usuario = this.usuarioService.getUser();
    const current = new Date();
    this.CalMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.addHorarioDia();
    this.mDate();
    this.ffiltroIniciar();
    this.iniciarForm();
  }

  gHorario = [];
  addHorarioDia() {
    for (let i = 0; i <= 23; i++) {
      let h = (i < 10 ? '0' + i : i)
      //this.gHorario.push(h + ':00');
      this.gHorario.push({ i: i, hora: h + ':00' });

    }
  }

  ngOnInit(): void {
    this.addDiasInhabiles();
    this.disableDays();
    this.addEspacio();

    // let isAdmin = this.usuarioService.adm();
    // if (isAdmin) {
    //   this.router.navigate(['/menu-admin'])
    // }
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idReservacion: [0],
      idEspacio: [0, [Validators.required]],
      idUsuario: [0],
      dia: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      horaFin: ['', [Validators.required]],
      inicio: [''],
      fin: [''],
      idEstatus: [0],
      equipos: [''],
      equipo: [''],
      comentarios: ['']
    })
    this.model = this.forma;
    this.recargar();
  }

  ffiltro: FormGroup;
  mfiltro;
  ffiltroIniciar() {
    //Filtro
    this.ffiltro = this.fb.group({
      idEspacio: [0],
    });
    this.mfiltro = this.ffiltro;
    this.mfiltro.idEspacio=""; //Para que seleccione por default al cargar
  }

  json = { disable: undefined, disabledDates: undefined };
  DiaInhabil;
  DiaInhabilLista
  addDiasInhabiles() {
    this.spinner.show();
    this.diaInhabilService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.DiaInhabil = data;
        let disDates = [];
        for (let i = 0; i < this.DiaInhabil.length; i++) {
          let arrDia = this.DiaInhabil[i].dia.split('T')[0].split('-');
          disDates.push({ year: Number(arrDia[0]), month: Number(arrDia[1]), day: Number(arrDia[2]) });
        }
        this.json.disable = [6, 7];
        this.json.disabledDates = disDates;
        console.log(this.json);
        this.disableDays();
      });
  }

  Espacio;
  EspacioLista;
  EspacioListaFiltro = [];
  mEspacioLista
  addEspacio() {
    this.spinner.show();
    this.espacioService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        console.log(data);
        this.Espacio = data;
        this.EspacioLista = Object.create(this.Espacio);
        this.EspacioListaFiltro = Object.create(this.Espacio);
        this.mEspacioLista = Object.create(this.Espacio);

      });
  }

  Equipo;
  EquipoLista;
  addEquipo() {
    this.spinner.show();
    this.equipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Equipo = data;
        //display: 'Lámpara', value: 1
        for (let i = 0; i < this.Equipo.length; i++) {
          this.Equipo[i]['display'] = this.Equipo[i].nombre;
          this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
        }
        this.EquipoLista = this.Equipo;
        this.addLista();
      });
  }

  Evento;
  events: Evento[];
  gEvents;
  Reservacion;
  ReservacionLista;
  addLista() {
    this.spinner.show();
    this.reservacionService.getDataByUser(this.Usuario.idUsuario).toPromise()
      .then(data => {
        this.spinner.hide();
        this.Reservacion = data;
        //Mostramos solo eventos actuales o futuros
        this.ReservacionLista = this.Reservacion.filter(x => x.idEstatus != 3 && this.dateIsValid(new Date(x.inicio)));

        for (let i = 0; i < this.ReservacionLista.length; i++) {
          this.ReservacionLista[i]['horaInicio'] = this.ReservacionLista[i].inicio.split('T')[1].substring(0, 5);
          this.ReservacionLista[i]['horaFin'] = this.ReservacionLista[i].fin.split('T')[1].substring(0, 5);
          const fecha = this.ReservacionLista[i].fin;
          const d1 = new Date();
          const d2 = new Date(fecha);
          this.ReservacionLista[i]["finalizada"] = false;
          if (d1.getTime() > d2.getTime()) {
            this.ReservacionLista[i]["finalizada"] = true;
            this.ReservacionLista[i].estatus = 'Realizada';
          }
        }

        this.events = this.ReservacionLista.map(x => ({
          comentarios: x.comentarios,
          idReservacion: x.idReservacion,
          idEspacioTipo: x.idEspacioTipo,
          idEspacio: x.idEspacio,
          idUsuario: x.idUsuario,
          tipo: x.espacioTipo,
          equipos: x.equipos,
          espacio: x.espacio,
          start: new Date(x.inicio),
          end: new Date(x.fin),
          horaFin: x.horaFin,
          horaInicio: x.horaInicio,
          title: x.espacio,
          color: util.colors[x.idEspacioTipo]
        }));

        this.gEvents = this.events;
        console.log(this.gEvents);

      });
  }

  buscarEspacio() {
    let hasValue = this.mfiltro.idEspacio != 0 && this.mfiltro.idEspacio !== undefined;
    this.filtrar(hasValue ? this.mfiltro.idEspacio : 0);
  }

  filtrar(idEspacio) {
    this.spinner.show();
    this.reservacionService.reservacionPorUsuarioEspacioTipoEspacio(this.Usuario.idUsuario, idEspacio).toPromise()
      .then(data => {
        this.spinner.hide();
        this.Reservacion = data;
        //Mostramos solo eventos actuales o futuros
        this.ReservacionLista = this.Reservacion.filter(x => x.idEstatus != 3 && this.dateIsValid(new Date(x.inicio)));

        for (let i = 0; i < this.ReservacionLista.length; i++) {
          this.ReservacionLista[i]['horaInicio'] = this.ReservacionLista[i].inicio.split('T')[1].substring(0, 5);
          this.ReservacionLista[i]['horaFin'] = this.ReservacionLista[i].fin.split('T')[1].substring(0, 5);
          const fecha = this.ReservacionLista[i].fin;
          const d1 = new Date();
          const d2 = new Date(fecha);
          this.ReservacionLista[i]["finalizada"] = false;
          if (d1.getTime() > d2.getTime()) {
            this.ReservacionLista[i]["finalizada"] = true;
            this.ReservacionLista[i].estatus = 'Realizada';
          }
        }

        this.events = this.ReservacionLista.map(x => ({
          comentarios: x.comentarios,
          idReservacion: x.idReservacion,
          idEspacioTipo: x.idEspacioTipo,
          idEspacio: x.idEspacio,
          idUsuario: x.idUsuario,
          tipo: x.espacioTipo,
          equipos: x.equipos,
          espacio: x.espacio,
          start: new Date(x.inicio),
          end: new Date(x.fin),
          horaFin: x.horaFin,
          horaInicio: x.horaInicio,
          title: x.espacio,
          color: util.colors[x.idEspacioTipo]
        }));

        this.gEvents = this.events;
        console.log(this.gEvents);

      });
  }

  restoreEspacioLista() {
    this.EspacioLista = this.Espacio;
  }

  HoraInicioLista;
  HoraFinLista;
  setEspacio() {
    if (!this.daySelected) this.model.dia = '';
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.FechaD = true;
    this.slideUpEquipos();
    if (this.model.idEspacio != 0 && this.model.idEspacio !== undefined) {
      if (!this.daySelected) this.FechaD = undefined;
    }
    if (this.model.dia != 0 && this.model.dia !== undefined) {
      this.setHorario();
    }
  }

  setHorario() {
    this.fechaSeleccionada = true;
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.slideUpEquipos();
    var dia = this.forma.controls["dia"].value;
    var diaSel = this.formatDate(dia);
    this.spinner.show();
    this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel }).subscribe(
      res => {
        this.HoraInicioLista = res;
        this.HorarioD = undefined;
        this.spinner.hide();
      },
      error => {
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );
  }

  setHoraFin() {
    this.model.horaFin = '';
    this.HorarioFinD = true;
    this.slideUpEquipos();
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.forma.controls["dia"].value;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.forma.controls["horaInicio"].value;
      this.spinner.show();
      this.reservacionService.getHorariosFin({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }
  }

  EquiposDisponibles;
  setEquipos() {
    if (this.model.horaFin != '' && this.model.horaFin !== undefined) {
      const dia = this.forma.controls["dia"].value;
      const diaSel = this.formatDate(dia);
      const horaInicio = this.forma.controls["horaInicio"].value;
      const horaFin = this.forma.controls["horaFin"].value;
      this.spinner.show();
      this.equipoService.equiposDisponibles({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio, HorarioFin: horaFin }).subscribe(
        res => {
          this.EquiposDisponibles = res.map(x => { x.cant = 0; return x });
          this.slideDownEquipos();
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    } else {
      this.slideUpEquipos();
    }
  }

  slideDownEquipos() {
    $('#equipoContent').slideDown({
      start: () => {
        $('#equipoContent').css({ display: 'flex' });
      }
    });
  }

  slideUpEquipos() {
    $('#equipoContent').slideUp(400, () => {
      this.EquiposDisponibles = [];
    });
  }

  minusEquipo(item) {
    if (item.cant > 0) {
      item.cant--;
    }
  }

  plusEquipo(item) {
    if (item.cant < item.cantidad) {
      item.cant++;
    }
  }

  hCancelar;
  nuevo(content) {
    this.iniciarForm();
    //this.restoreEspacioLista();
    this.model.activo = true;
    this.hCancelar = true;
    this.bEdicion = undefined;
    this.FechaD = true;
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.modal.open(content, { size: 'xl' });
    this.daySelected = false;
  }

  bEdicion = undefined;
  oTmp;
  editar(content, modelo) {
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    this.restoreEspacioLista();
    this.hCancelar = false;
    this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.oTmp = { ...modelo }; 
    //this.modal.open(content, { size: 'xl' });
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      var idx = this.ReservacionLista.findIndex(x => x.idReservacion == this.oTmp.idReservacion); 
      this.ReservacionLista[idx] = this.oTmp;      
      return true;  
    }}); 
    for (let e of modelo.equipos) {
      e.cant = e.cantidad;
    }
    this.EquiposDisponibles = modelo.equipos;
    this.slideDownEquipos();
  }

  guardar(idEstatus) {
    this.spinner.show();
    var dia = this.forma.controls["dia"].value;
    var hinicio = this.forma.controls["horaInicio"].value;
    var hfin = this.forma.controls["horaFin"].value;
    var idEspacio = this.forma.controls["idEspacio"].value;
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + 'T' + hinicio + ':00';
    var fin = dInicio + 'T' + hfin + ':00';
    this.forma.controls["inicio"].setValue(inicio);
    this.forma.controls["fin"].setValue(fin);
    this.forma.controls["idUsuario"].setValue(this.Usuario.idUsuario);

    //Si no es una cancelación, determinamos el estatus según el espacio
    if (idEstatus != 3) {
      let espacio = this.Espacio.find(x => x.idEspacio === idEspacio);
      if (espacio.autorizacion) { idEstatus = 1; }
    }
    this.forma.controls["idEstatus"].setValue(idEstatus);

    let arr = [];
    for (let e of this.EquiposDisponibles) {
      if (e.cant > 0) {
        e.cantidad = e.cant;
        arr.push(e);
      }
    }
    this.forma.controls["equipos"].setValue(arr);

    if (this.forma.controls["idReservacion"].value == undefined) {
      this.reservacionService.addData(this.forma.value).subscribe(
        (res) => { this.toastr.success("La información ha sido enviada"); this.recargar(); this.spinner.hide(); },
        (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
    }
    else {
      this.reservacionService.updData(this.forma.controls["idReservacion"].value, this.forma.value).subscribe(
        (res) => { this.toastr.success("La información ha sido actualizada"); this.recargar(); this.spinner.hide(); },
        (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
    }

  }

  CancelarReservacionViaConfirmacion() {
    Swal.fire({
      title: 'Cancelar Reservación',
      input: 'textarea',
      text: "Se cancelará la reservación. Déjanos un comentario con el motivo de la cancelación.",
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Cancelar Reservación',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {
        this.spinner.show();
        this.reservacionService.cancelarReservacion({ IdReservacion: this.model.idReservacion, MotivoCancelacion: comentario }).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success("La reservación ha sido cancelada"); this.recargar();
          },
          error => {
            if (typeof error.error === 'object') {
              this.toastr.error(error.message);
            } else {
              this.toastr.error(error.error);
            }
            this.spinner.hide();
          }
        );
        return true;
      }
    });
  }

  FechaDeReservacionTieneMasDe24hrPrevioAEvento(): boolean {
    var dateInicio = new Date(this.forma.controls["dia"].value);
    var hinicio = this.forma.controls["horaInicio"].value;

    let splitedTimeElements = hinicio.split(":");
    let hour = Number(splitedTimeElements[0]);
    let minute = Number(splitedTimeElements[1]);

    let dateObj1: Date = new Date(dateInicio.getFullYear(), dateInicio.getMonth(), dateInicio.getDate(), hour, minute, 0, 0);
    console.log(dateObj1);
    let hourDifference = (dateObj1.getTime() - (new Date()).getTime()) / 1000 / 60 / 60;
    console.log(hourDifference);

    return hourDifference >= 24;
  }

  cancelar() {

    if (this.FechaDeReservacionTieneMasDe24hrPrevioAEvento()) {
      this.CancelarReservacionViaConfirmacion();
    } else {
      this.toastr.error("No se puede cancelar reservación, es con menos de 24h para el evento.");
    }

  }

  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  eventClicked(content, evento) {

    let idReservacion = evento.event.idReservacion;
    let idUsuarioLoggeado = this.usuarioService.getUser().idUsuario;

    this.usuarioService.VerificarSiElUsuarioEsElCreadorDelEventoADesplegar(idReservacion, idUsuarioLoggeado)
      .toPromise()
      .then(result => {
        let usuario: any = result;
        if (usuario.fueBuscadoPeroSeGeneroUnaExcepcion) {
          this.toastr.error("No se pudo verificar los permisos de su cuenta. Favor de intentar mas tarde.");
          return;
        }
        if (!usuario.esElCreadorDeLaReservacion && !usuario.esAdministrador) {
          this.toastr.error("No se puede editar una tarea que no fue creada por usted.");
          return;
        } else {
          this.MostrarVentanaDeReservacion(content, evento);
        }
      });
  }


  MostrarVentanaDeReservacion(content, evento) {
    const modelo = evento.event;
    this.oTmp = { ...modelo };
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    //console.log(this.model);
    this.model.dia = new Date(this.model.start);
    this.restoreEspacioLista();
    this.hCancelar = false;
    this.bEdicion = true;
    console.log(this.oTmp);
    //this.modal.open(content, { size: 'xl' });
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      //var idx = this.ReservacionLista.findIndex(x => x.idReservacion == this.oTmp.idReservacion); 
      //this.ReservacionLista[idx] = this.oTmp;
     //this.recargar();
     //console.log(this.model);
     //this.model.comentarios = this.oTmp.comentarios;
     this.model.idEspacio = this.oTmp.idEspacio;
     this.model.dia = this.oTmp.dia;
     this.model.horaInicio = this.oTmp.horaInicio;
     this.model.horaFin = this.oTmp.horaFin;
     this.model.idEstatus = this.oTmp.idEstatus;
     this.model.comentarios = this.oTmp.comentarios;
     //this.model = this.oTmp;
     //console.log(this.model);

     return true;  
    }}); 
    for (let e of modelo.equipos) {
      e.cant = e.cantidad;
    }
    this.EquiposDisponibles = modelo.equipos;
    this.slideDownEquipos();
  }

  dayHeaderClicked(content, evento) {
    this.dayClicked(content, evento);
  }

  calCliked(content, evento) {
    let tmp;
    if (evento.day == undefined) {
      tmp = { day: { date: new Date() } }
    }
    else {
      tmp = { day: evento }
    }
    this.dayClicked(content, tmp);
  }
  /*
  dateDragEnter(evn){
    console.log(evn);
    alert('evento');  
  }
  */

  //dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  //dayClicked(content, evento): void {
  dayClicked(content, evento) {
    this.hCancelar = true;
    //console.log(evento);
    //alert(evento.day.date +" > "+ this.minDate);
    //Si no es una fecha válida (actual o futura), no mostramos el modal
    //alert(this.dateIsValid(evento.day.date));
    //if(!this.dateIsValid(evento.day.date)){return false;}
    this.iniciarForm();
    //this.restoreEspacioLista(); //DAAE
    this.bEdicion = undefined;
    this.FechaD = true;
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.model.activo = true;
    this.daySelected = true;
    this.model.dia = new Date(evento.day.date);
    /*var dia = this.model.dia.toISOString();
    this.model.horaInicio = dia.split('T')[1].substring(0,5);*/
    this.modal.open(content, { size: 'xl' });

    let date = evento.date;
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
    this.recargar();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  uf_Date(obj) { return util.uf_Date(obj); }
  formatDate(obj) { return util.formatDate(obj); }
  isToday(obj) { return util.isToday(obj); }

  //Desabilitar fechas pasadas
  //**************************ngbCalendar
  isDisabled;
  disableDays() {
    //to disable specific date and specific weekdays
    this.isDisabled = (
      date: NgbDateStruct
      //current: { day: number; month: number; year: number }
    ) => {
      return this.json.disabledDates.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
        || (this.json.disable.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
      )
        ? true
        : false;
    };
  }
  //**************************AngularCalendar
  // Define a minimum date (to disable everything in the past you can just write this)
  minDate: Date = new Date();
  mDate() {
    this.minDate.setDate(this.minDate.getDate() - 1);
  }

  // Validator function
  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }

  // Event handler which you bind to the `<mwl-calendar-week-view>` component in the html template
  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      hourCol.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          if (!this.dateIsValid(segment.date)) {
            segment.cssClass = 'cal-disabled';
          }
        });
      });
    });
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }
  //************************** */


}
