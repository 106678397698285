import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../data-access/config';

@Injectable({
  providedIn: 'root'
})
export class RHService {
  //usrauth = 'faf36f798ba8a9a25a50d1ce9c1925ce';
  iberourl = 'https://planeacionestrategica.ibero.mx/ws/WSServiciosIbero.cfc?wsdl';
  //& usrauth=faf36f798ba8a9a25a50d1ce9c1925ce';

  constructor(private http: HttpClient) {

  }

  validar() {

    let valido = localStorage.getItem('valido');
    //console.log(valido);
    return (valido === '1')

  }

  isAdmin() {
    let rol = localStorage.getItem('rol'); //JSON.parse(localStorage.getItem('rol')) || [];
    //let rol = JSON.parse(localStorage.getItem('rol')) || [];
    //console.log(rol);
    return (rol === 'admin')
  }

  //Get
  empleadosSubordinados(cuenta:undefined) {
    /*
    let method = 'empleadosSubordinados';
    console.log(this.iberourl + '&method=' + method + '&cuenta=' + cuenta + '&usrauth=faf36f798ba8a9a25a50d1ce9c1925ce');
    return this.http.get(this.iberourl + '&method=' + method
      + '&cuenta=' + cuenta + '&usrauth=faf36f798ba8a9a25a50d1ce9c1925ce');
      */
    console.log(config.apiUrl + '/externo/empleadosSubordinados/' + cuenta);
    return this.http.get(config.apiUrl + '/externo/empleadosSubordinados/' + cuenta);
  }

  //Get
  datosEmpleado(cuenta:undefined) {
    console.log(config.apiUrl + '/externo/datosEmpleado/' + cuenta);
    return this.http.get(config.apiUrl + '/externo/datosEmpleado/' + cuenta);
  }

}
