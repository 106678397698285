<!--Contenedor-->
<div class="container align-items-center flex-column">

  <div class="row justify-content-between py-1">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-section">Tipo de Espacio</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
        <!--button class="btn btn-danger" (click)="nuevo(content)">Agregar</button-->
        <button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Agregar" (click)="nuevo(content)">
          <la-icon [icon]="['las','plus']" size="1x" class="text-white"></la-icon>
        </button>
        <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
          <la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon>
        </button>
    </div>
  </div>
  
    <!--Listado-->
    <!--div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">&nbsp;</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div-->
  
    <!--table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado">idTipoEspacio</th>
          <th scope="col" class="encabezado">Nombre</th>          
          <th scope="col" class="encabezado">Activo</th>
          <th scope="col" class="encabezado">Acciones</th>          
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of EspacioTipoLista; let i = index">
          <td class="w-10">{{ item.idEspacioTipo }}</td>
          <td class="w-10">{{ item.nombre }}</td>
          <td class="w-15">
            {{ item.estatus?"Activo":"Inactivo" }}
          </td>           
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="8">{{ (EspacioTipoLista )?.length }} Registros</td>
        </tr>
      </tfoot>
    </table-->
    <div class="row f-13 bg-secondary text-white">
      <div class="col-md-8 text-center">Nombre</div>
      <div class="col-md-2 text-center">Estatus</div>
      <div class="col-md-2 text-center">Acciones</div>
    </div>
    <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''" *ngFor="let item of EspacioTipoLista; let i = index">
      <div class="col-md-8 text-center">{{ item.nombre }}</div>
      <div class="col-md-2 text-center">{{ item.activo==1?"Activo":"Inactivo" }}</div>
      <div class="col-md-2 text-center">
        <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
          <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
        </button>
      </div>
    </div>
    <div class="row f-13 bg-secondary text-white">
      <div class="col-md-12 text-center">{{ (EspacioTipoLista)?.length }} Registros</div>
    </div>

  </div>
  
  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Datos del Tipo de Espacio</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="idEspacioTipo"
                         [(ngModel)]="model.idEspacioTipo">
                </div>
                <div class="row text-dark">
                  <div class="form-group col-lg-6">
                    <label for="nombre">Nombre:</label>
                    <input class="form-control" type="text" formControlName="nombre"
                           [(ngModel)]="model.nombre">
                           <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">Este campo es requerido</div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="estatus">Estatus</label>
                    <select class="form-control" formControlName="estatus"
                            [(ngModel)]="model.estatus">
                      <option value="">--Seleccionar--</option>
                      <option [ngValue]="true">Activo</option>
                      <option [ngValue]="false">Inactivo</option>
                    </select>
                    <div class="text-danger" *ngIf="forma.controls['estatus'].touched && forma.controls['estatus'].errors?.required">Este campo es requerido</div>
                  </div>
                </div>
                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.dismiss()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
