import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { RolService } from 'src/app/data-access/rol.service';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styles: [
  ]
})
export class RolComponent implements OnInit {
  forma: FormGroup;
  model;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private rolService: RolService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    //this.addLista();
    this.addEspacioTipo();
    //this.addFiltros();
  }

  iniciarForm() {
    //Principal
    this.forma = this.fb.group({
      idRol: [0],
      nombre: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      espacios: [''],
      espacio: [''],
      estatus: [true, [Validators.required]]
    });
    this.model = this.forma;
  }

  Rol;
  RolLista;
  addLista() {
    this.spinner.show();
    this.rolService.getData().toPromise()
      .then(data => {
        this.Rol = data;
        //Asignamos el universo de items al listado, mediante una entidad de filtro
        this.RolLista = this.Rol;
        console.log(this.RolLista);

        for(let i=0; i<this.RolLista.length;i++){
          if(this.RolLista[i].espacios!=null && this.RolLista[i].espacios!=""){
            var arrLista = this.EspacioTipo
              .filter(x => this.RolLista[i].espacios.split(',').includes(x.idEspacioTipo.toString()))
                .map(function(item) { return item.nombre;} );
            this.RolLista[i]['eLista'] = arrLista.join(', ');
          }
        }
        console.log(this.RolLista);
        this.spinner.hide();
      })
  }

  EspacioTipo;
  EspacioTipoLista;
  addEspacioTipo(){
    this.spinner.show();
    this.espacioTipoService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.EspacioTipo = data;
      for(let i=0; i<this.EspacioTipo.length;i++){
        this.EspacioTipo[i]['display'] = this.EspacioTipo[i].nombre;
        this.EspacioTipo[i]['value'] = this.EspacioTipo[i].idEspacioTipo;
      }
      this.EspacioTipoLista = this.EspacioTipo;
      this.addLista();
    });
  }

  nuevo(rol) {
    this.iniciarForm();
    this.modal.open(rol, { size: 'xl' });
  }

  editar(content, modelo) {
    if(modelo.espacios!=null && modelo.espacios!=""){
      let arrEspacioTipo = modelo.espacios.split(',');
      modelo["espacio"] = this.EspacioTipoLista.filter(x => arrEspacioTipo.includes(x.idEspacioTipo.toString()));
    }
    this.model = modelo;
    this.modal.open(content, { size: 'xl' });
  }

  guardar() {
    this.spinner.show();
    let espacio = this.forma.controls["espacio"].value;
    console.log(espacio);
    let oArr = [];
    for (let i = 0; i < espacio.length; ++i) {
      oArr.push(espacio[i].idEspacioTipo);
    }
    this.forma.controls["espacios"].setValue(oArr.toString());
    console.log(this.forma.value);
    if (this.forma.controls["idRol"].value == undefined) {
      this.rolService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.rolService.updData(this.forma.controls["idRol"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
          (error) => {console.log(error);});
    }
  }
  
  recargar() {
    this.spinner.hide();
    this.modal.dismissAll();
    this.addLista();
  }

}
