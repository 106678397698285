import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DiaInhabilService } from 'src/app/data-access/diainhabil.service';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-diainhabil',
  templateUrl: './diainhabil.component.html',
  styles: [
  ]
})
export class DiaInhabilComponent implements OnInit {
  forma: FormGroup;
  model;
  p: number = 1;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService,
    private diaInhabilService: DiaInhabilService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addLista();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idDiaInhabil: [0],
      dia: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      estatus: [true, [Validators.required]]
    })
    this.model = this.forma;
  }
  
  DiaInhabil;
  DiaInhabilLista;
  addLista(){
    this.spinner.show();
    this.diaInhabilService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.DiaInhabilLista = data;
      //console.log(data);
    });
  }
  
  nuevo(content) {
    this.iniciarForm();
    //this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }
  oTmp;
  editar(content, modelo) {
    this.model = modelo;
    this.model.dia = new Date(modelo.dia);
    //this.modal.open(content,{size:'xl'});
    this.oTmp = { ...modelo }; 
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      var idx = this.DiaInhabilLista.findIndex(x => x.idDiaInhabil == this.oTmp.idDiaInhabil); 
      this.DiaInhabilLista[idx] = this.oTmp;       
      return true;  
    }}); 
  }
  
  guardar() {
    //console.log(this.forma.value);
    if (this.forma.controls["idDiaInhabil"].value == undefined) {
      this.diaInhabilService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.diaInhabilService.updData(this.forma.controls["idDiaInhabil"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
          (error) => {console.log(error);});
    }
    
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }
}


