<div class="bg-login">
<div class="container">
  <div class="row align-items-center minh-100">
    <div class="col-md-12">
      <div class="card shadow-lg border-0 rounded-lg mt-2" id="box-login">
        <div class="card-header text-center" [ngClass]="bgapp">
          <img src="../../../../assets/img/login/{{logo}}" class="img-login" id="img-login" />
        </div>
        <div class="card-body">

          <h3 class="text-danger text-center mb-3">
            El usuario se encuentra Inactivo.
          </h3>  
          
          <h3 class="text-danger text-center mb-3">
            Favor de cerrar esta ventana.
          </h3>  


        </div>
        <div class="small mb-1 text-center">
          <div class="small"><label class="text-white">Versión 2.1.0</label></div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
