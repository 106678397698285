import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { TipoUsuarioService } from 'src/app/data-access/tipoUsuario.service';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usuario-tipo',
  templateUrl: './usuario-tipo.component.html',
  styles: [
  ]
})
export class UsuarioTipoComponent implements OnInit {
  forma: FormGroup;
  model;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private tipoUsuarioService: TipoUsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    //this.addLista();
    this.addEspacioTipo();
    //this.addFiltros();
  }

  iniciarForm() {
    //Principal
    this.forma = this.fb.group({
      idTipoUsuario: [0],
      descripcion: ['', [Validators.required]],
      espacios: [''],
      espacio: [''],
      activo: [true, [Validators.required]]
    });
    this.model = this.forma;
  }

  UsuarioTipo;
  UsuarioTipoLista;
  addLista() {
    this.spinner.show();
    this.tipoUsuarioService.getData().toPromise()
      .then(data => {
        this.UsuarioTipo = data;
        //Asignamos el universo de items al listado, mediante una entidad de filtro
        this.UsuarioTipoLista = this.UsuarioTipo;
        console.log(this.UsuarioTipoLista);

        for(let i=0; i<this.UsuarioTipoLista.length;i++){
          if(this.UsuarioTipoLista[i].espacios!=null && this.UsuarioTipoLista[i].espacios!=""){
            var arrLista = this.EspacioTipo
              .filter(x => this.UsuarioTipoLista[i].espacios.split(',').includes(x.idEspacioTipo.toString()))
                .map(function(item) { return item.nombre;} );
            this.UsuarioTipoLista[i]['eLista'] = arrLista.join(', ');
          }
        }
        console.log(this.UsuarioTipoLista);
        this.spinner.hide();
      })
  }

  EspacioTipo;
  EspacioTipoLista;
  addEspacioTipo(){
    this.spinner.show();
    this.espacioTipoService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.EspacioTipo = data;
      for(let i=0; i<this.EspacioTipo.length;i++){
        this.EspacioTipo[i]['display'] = this.EspacioTipo[i].nombre;
        this.EspacioTipo[i]['value'] = this.EspacioTipo[i].idEspacioTipo;
      }
      this.EspacioTipoLista = this.EspacioTipo;
      this.addLista();
    });
  }

  nuevo(rol) {
    this.iniciarForm();
    this.modal.open(rol, { size: 'xl' });
  }

  editar(content, modelo) {
    if(modelo.espacios!=null && modelo.espacios!=""){
      let arrEspacioTipo = modelo.espacios.split(',');
      modelo["espacio"] = this.EspacioTipoLista.filter(x => arrEspacioTipo.includes(x.idEspacioTipo.toString()));
    }
    this.model = modelo;
    this.modal.open(content, { size: 'xl' });
  }

  guardar() {
    this.spinner.show();
    let espacio = this.forma.controls["espacio"].value;
    console.log(espacio);
    let oArr = [];
    for (let i = 0; i < espacio.length; ++i) {
      oArr.push(espacio[i].idEspacioTipo);
    }
    this.forma.controls["espacios"].setValue(oArr.toString());
    console.log(this.forma.value);
    /*
    if (this.forma.controls["idTipoUsuario"].value == undefined) {
      this.tipoUsuarioService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
    */
      this.tipoUsuarioService.updData(this.forma.controls["idTipoUsuario"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
          (error) => {console.log(error);});
    //}
  }
  
  recargar() {
    this.spinner.hide();
    this.modal.dismissAll();
    this.addLista();
  }

}
