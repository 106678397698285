/* Date Functions*/
export function formatDate(fecha) {
  var d = new Date(fecha);
  //d.setHours(0, 0, 0, 0);
  return [
    d.getFullYear(),
    ('0' + (d.getMonth() + 1)).slice(-2),
    ('0' + d.getDate()).slice(-2)  
  ].join('-');
}

export function uf_Date(fecha) {
  var d = new Date(fecha);
  //d.setHours(0, 0, 0, 0);
  return [
    ('0' + d.getDate()).slice(-2),
    ('0' + (d.getMonth() + 1)).slice(-2),
    d.getFullYear()
  ].join('/');
}

export function isToday(dia){
  let d = new Date();
  let hoy = [
    d.getFullYear(),
    ('0' + d.getDate()).slice(-2),
    ('0' + (d.getMonth() + 1)).slice(-2)
  ].join('-');

  let fecha = [
    dia.getFullYear(),
    ('0' + dia.getDate()).slice(-2),
    ('0' + (dia.getMonth() + 1)).slice(-2)
  ].join('-');
  //alert(hoy+'=='+fecha);
  return (hoy==fecha);
}

/* Calendar Functions*/
export const colors: any = {
  1: {
    primary: '#e3f2fd',
    secondary: '#e3f2fd',
  },
  2: {
    primary: '#bbdefb',
    secondary: '#bbdefb',
  },
  3: {
    primary: '#90caf9',
    secondary: '#90caf9',
  },
  4: {
    primary: '#64b5f6',
    secondary: '#64b5f6',
  },
  5: {
    primary: '#42a5f5',
    secondary: '#42a5f5',
  },
  6: {
    primary: '#2196f3',
    secondary: '#2196f3',
  },
  7: {
    primary: '#1e88e5',
    secondary: '#1e88e5',
  },
  8: {
    primary: '#1976d2',
    secondary: '#1976d2',
  },
  9: {
    primary: '#1565c0',
    secondary: '#1565c0',
  },  
  10: {
    primary: '#0d47a1',
    secondary: '#0d47a1',
  }, 
};