<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado
    <div class="text-center pt-2">
      <h4 class="title-section">Resrvaciones</h4>
    </div>
    -->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">Histórico</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
        <button class="btn btn-danger mr-1" ngbTooltip="Reservaciones" [routerLink]="['/e-reservacion/']" >
          <la-icon [icon]="['las','list']" class="text-white"></la-icon>
        </button>
        <button class="btn btn-danger mr-1" [routerLink]="['/e-calendario/']" ngbTooltip="Calendario">
          <la-icon [icon]="['las','calendar']" class="text-white"></la-icon>
        </button>
        <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu']">
          <la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon>
        </button>
      </div>
    </div>
  
    <!--Listado-->
    <!--div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">Mis Reservaciones</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div-->
  
    <!--table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado">#</th>
          <th scope="col" class="encabezado">Tipo</th>
          <th scope="col" class="encabezado">Espacio</th>
          <th scope="col" class="encabezado">Inicio</th>
          <th scope="col" class="encabezado">Fin</th>
          <th scope="col" class="encabezado">Acciones</th>          
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of events; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <td class="w-10">{{ item.tipo }}</td>
          <td class="w-10">{{ item.title }}</td>
          <td class="w-10">{{ util_fdt(item.start) }}</td>
          <td class="w-10">{{ util_fdt(item.end) }}</td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="8">{{ (events)?.length }} Eventos</td>
        </tr>
      </tfoot>
    </table-->

    <div class="row f-13 bg-secondary text-white">
      <div class="col-md-3 text-center">Espacio</div>
      <div class="col-md-3 text-center">Usuario</div>
      <div class="col-md-2 text-center">Inicio</div>
      <div class="col-md-2 text-center">Fin</div>
      <div class="col-md-2 text-center">Estatus</div>
      <!--div class="col-md-1 text-center">Acciones</div-->
    </div>
    <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''" *ngFor="let item of ReservacionLista | paginate: { itemsPerPage:15, currentPage: p }; let i = index">
      <div class="col-md-3 text-center">{{ item.espacio }}</div>
      <div class="col-md-3 text-center">{{ item.nombre}} {{ item.apPat}} {{ item.apMat}}</div>
      <div class="col-md-2 text-center">{{ uf_Date(item.inicio) }} {{item.horaInicio}} Hrs.</div>
      <div class="col-md-2 text-center">{{ uf_Date(item.fin) }} {{item.horaFin}} Hrs.</div>
      <div class="col-md-2 text-center">{{ item.estatus }}</div>
      <!--div class="col-md-1 text-center">
        <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
          <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
        </button>
      </div-->
    </div>
    <div class="row f-13 bg-secondary text-white">
      <div class="col-md-12 text-center">{{ (ReservacionLista)?.length }} Registros</div>
    </div>
    <pagination-controls (pageChange)="p = $event" previousLabel="Anterior"
    nextLabel="Siguiente" class="text-center"></pagination-controls>

  </div>
  
  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Reservación</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label>Tipo</label>
                  </div>
                  <div class="col-md-4">
                    <select class="form-control" formControlName="tipo" 
                    [(ngModel)]="model.tipo" (change)="filtraTipo()">
                      <option value="0">--Seleccionar--</option>
                      <option value="1">Biblioteca</option>
                      <option value="2">Audiovisual</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <label>Espacio</label>
                  </div>
                  <div class="col-md-4">
                    <select class="form-control" formControlName="espacio" 
                    [(ngModel)]="model.espacio" (change)="filtraTipo()">
                      <option value="0">--Seleccionar--</option>
                      <option value="1">Cubículo 01</option>
                      <option value="2">Cubículo 02</option>
                      <option value="3">Sala de Audio</option>
                      <option value="4">Sala de Video</option>        
                      <option value="5">Sala de Audio y Video</option>
                    </select> 
                  </div>    
                </div>
                <div class="form-group row">

                  <div class="col-md-2">
                    <label for="inicio"><span class="text-danger">*</span>Fecha:</label>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group">
                      <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" 
                      [readonly]="true" [(ngModel)]="model.inicio"
                             id="inicio" formControlName="inicio" ngbDatepicker #d="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                      <input class="form-control" type="time" value="08:00:00" formControlName="hora"
                      [(ngModel)]="model.hora">                      
                    </div>
                  </div>
                  
                  <div class="col-md-2">
                    <label for="duracion"><span class="text-danger">*</span>Duración</label>
                  </div>
                  <div class="col-md-4">
                    <input type="number" class="form-control" min="0" max="24" id="duracion"
                    formControlName="duracion" [(ngModel)]="model.duracion">
                  </div> 
                </div>

                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.dismiss()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>