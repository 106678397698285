import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { PerfilComponent } from './perfil/perfil.component';
import { InicioComponent } from './inicio/inicio.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { InactivoComponent } from './inactivo/inactivo.component';
import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import { lasSave, lasEllipsisH, 
  lasUsers, lasUserShield, lasUserCog, lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
  lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe, 
  lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher, lasBriefcase, lasEnvelope, lasAddressBook, 
  lasMoneyCheckAlt, lasQuestion, lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasSignOutAlt, 
  lasTools, lasUser, lasLaptopMedical
 } from 'angular-line-awesome/icons';


@NgModule({
  declarations: [FooterComponent, LoginComponent, PerfilComponent, InicioComponent, NavMenuComponent, InactivoComponent],
  imports: [
	  CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AngularLineawesomeModule
  ],
  //exports: [FooterComponent, LoginComponent, PerfilComponent, InicioComponent, NavMenuComponent]
  exports: [NavMenuComponent]

})
export class ShellModule { 
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSave, lasEllipsisH, 
      lasUsers, lasUserShield, lasUserCog, 
      lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
      lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
      lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher,
      lasBriefcase, lasEnvelope, lasAddressBook,
      lasMoneyCheckAlt, lasQuestion, lasShieldAlt,
      lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasTools,
      lasUser, lasUserShield, lasLaptopMedical, lasSignOutAlt
    ]);
  }  
}
