
<!--nav class="navbar navbar-expand-lg navbar-light p-0" [ngClass]="bgapp" id="nav">
  <div class="container" >
    <a class="navbar-brand" [routerLink]="" (click)="home()">
      <img src="assets/img/login/{{logo}}" width="100">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul class="navbar-nav ">

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarUsuario" 
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ us.usr() }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarUsuario">
            <a class="dropdown-item" [routerLink]="['/perfil/']">Perfil</a>
            <a class="dropdown-item" [routerLink]="" (click)="logout()">Cerrar sesión</a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarEstudiante" *ngIf="us.es()"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Espacios Ibero
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarEstudiante">
            <a class="dropdown-item" [routerLink]="['/e-calendario/']">Calendario</a>
            <a class="dropdown-item" [routerLink]="['/e-reservacion/']">Reservaciones</a>
            <a class="dropdown-item" [routerLink]="['/e-historico/']">Histórico</a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarPlaneacion" *ngIf="us.adm()"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Administración
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarPlaneacion">
            <a class="dropdown-item" [routerLink]="['/usuario/']">Usuarios</a>
            <a class="dropdown-item" [routerLink]="['/rol/']">Roles</a>            
            <a class="dropdown-item" [routerLink]="['/solicitud/']">Solicitudes</a>            
          </div>

        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarAdministrador"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="us.adm()">
            Catálogos
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarAdministrador">
            <a class="dropdown-item" [routerLink]="['/espaciotipo/']">Tipo de Espacios</a>
            <a class="dropdown-item" [routerLink]="['/espacio/']">Espacios</a>
            <a class="dropdown-item" [routerLink]="['/equipo/']">Equipos</a>
          </div>

        </li>
      </ul>
    </div>

  </div>
  </nav-->
  <div class="card-header text-center text-white" [ngClass]="bgapp" >
    <img src="../../../../assets/img/login/{{logo}}" class="img-login mr-2" id="img-login" />
    <button class="btn icon-btn text-right bg-white mr-2" [routerLink]="['/menu']" ngbTooltip="Usuario">
      <la-icon [icon]="'laptop-medical'" size="1x" class="text-danger"></la-icon>
    </button>
    <button class="btn icon-btn text-right bg-white" [routerLink]="['/menu-admin']" ngbTooltip="Administrador" *ngIf="us.adm()">
      <la-icon [icon]="'cog'" size="1x" class="text-danger"></la-icon>
    </button>  
    <p class="mb-0">{{NombreCompleto}}
        <button class="btn icon-btn text-right ml-1" ngbTooltip="Salir" (click)="logout()">
            <la-icon [icon]="['las','sign-out-alt']" size="1x" class="text-white"></la-icon>
        </button>
    </p>
  </div>
  