<div class="bg-login">
<div class="container">
  <div class="row align-items-center minh-100">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="card shadow-lg border-0 rounded-lg" id="box-login">
        <div class="card-header"><h3 class="text-center font-weight-light my-4">Iniciar Sesión</h3></div>
        <div class="card-body">

            <div *ngIf="!showLogin" class="d-flex align-items-center text-center justify-content-center" style="height: 335px">
                <h5>Accediendo a la cuenta de IBERO...</h5>
            </div>

          <form *ngIf="showLogin" autocomplete="nope" [formGroup]="flogin" >
            <!--div class="form-group text-center" >
              <ng-container *ngFor="let item of Roles; let c = index">
                <label for="{{ item.idRol }}" class="mr-2">
                  <input type="radio" name="idRol" 
                  id="{{item.idRol}}" value={{item.idRol}}
                  [checked]="(item.idRol===2) ? true : false"
                  >
                  <span class="label-text ml-1">{{item.clave}}</span>
                </label>
              </ng-container>
            </div-->
            <style>
                .form-control:disabled, .form-control[readonly] {
                    background-color: #fff;
                    opacity: 1;
                }
            </style>
            <div class="form-group"><label class="small mb-1" for="cuenta">Cuenta</label>
              <input autocomplete="nope" class="form-control py-4" id="cuenta" type="text" placeholder="Ingrese su usuario"
              formControlName="cuenta" [(ngModel)]="mlogin.cuenta" />
            </div>
            <!--div class="form-group"><label class="small mb-1" for="digito">Digito</label>
              <input autocomplete="nope" class="form-control py-2" id="digito" type="text" placeholder="Digito"
                  formControlName="digito" [(ngModel)]="mlogin.digito" 
                  maxlength="1" (keypress)="alphanumericOnly($event)" (click)="turnOn()" (focus)="turnOn()" [readonly]="notReady"/>
            </div-->
            <div class="form-group"><label class="small mb-1" for="clave">Contraseña</label>
              <input autocomplete="nope" class="form-control py-4" id="clave" type="password" placeholder="Ingrese su contraseña"
              formControlName="clave" [(ngModel)]="mlogin.clave" />
            </div>
            <!--re-captcha  siteKey="6LcViLsZAAAAAMeBjvn6NHAI6tE2b1Kgn9KloLEN" formControlName="recaptcha"></re-captcha-->          
            <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
            <button class="btn btn-danger text-white" (click)="entrar()" >Entrar</button>
            </div>
            <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
              <!--div class="text-center mr-2">
                <a [routerLink]="['/recuperacion']" class="text-dark">¿Olvidó su contraseña?</a>
              </div--> 
              <!--div class="text-center">
                <a [routerLink]="['/registro']" class="text-dark">Registrarse</a>
              </div-->              

              
            </div>
           
          </form>

        </div>
        <!--div class="card-footer text-center">
          <div class="small"><a routerLink="/auth/register">Need an account? Sign up!</a></div>
        </div-->
        <div class="small mb-1 text-center">
          <div class="small"><label class="text-white">Versión 2.1.0</label></div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
