<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Usuarios</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Agregar" (click)="nuevo(content)">
        <la-icon [icon]="['las','plus']" size="1x" class="text-white"></la-icon>
      </button>
      <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
        <la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon>
      </button>
    </div>
  </div>

  <!--Listado-->
  <div class="row bg-secondary text-white">
    <div class="col-md-1 text-center ">Cuenta</div>
    <div class="col-md-3 text-center ">Nombre</div>
    <div class="col-md-3 text-center ">Email</div>
    <div class="col-md-2 text-center ">Rol</div>
    <div class="col-md-1 text-center ">Estatus</div>
    <div class="col-md-2 text-center ">Acciones</div>
  </div>
  <div class="row" [ngClass]="i%2 == 0 ? 'bg-dee':''"
    *ngFor="let item of UsuarioLista | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
    <div class="col-md-1 text-center ">{{ item.cuenta }}</div>
    <div class="col-md-3 text-center ">{{ item.nombre }} {{ item.apPat }} {{ item.apMat }}</div>
    <div class="col-md-3 text-center ">{{item.email}}</div>
    <div class="col-md-2 text-center ">{{ item.eLista }}</div>
    <div class="col-md-1 text-center ">{{ item.estatus?"Activo":"Inactivo" }}</div>
    <div class="col-md-2 text-center ">
      <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
        <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
      </button>
    </div>
  </div>
  <div class="row bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (UsuarioLista)?.length }} Registros</div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente" class="text-center">
  </pagination-controls>
</div>
<div class="row bg-secondary text-white">
  <div class="col-md-12 text-center">{{ (UsuarioLista)?.length }} Registros</div>
</div>


<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos del usuario</div>
      <div class="card-body text-dark">

        <form [formGroup]="forma">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="idUsuario" [(ngModel)]="model.idUsuario">
            <input class="form-control" type="hidden" formControlName="digito" [readonly]="true"
              [(ngModel)]="model.digito">
            <input class="form-control" type="hidden" formControlName="claveCoordinacion" [readonly]="true"
              [(ngModel)]="model.claveCoordinacion">
            <input class="form-control" type="hidden" formControlName="claveDepartamento" [readonly]="true"
              [(ngModel)]="model.claveDepartamento">
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="cuenta"><span class="text-danger">*</span>Cuenta:</label>
              <div class="row text-dark">
                <div class="form-group col-md-8">
                  <input class="form-control" type="text" formControlName="cuenta" [readonly]="false"
                    [(ngModel)]="model.cuenta">
                </div>
                <div class="form-group col-md-3">
                  <button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Buscar"
                    (click)="Evento_Buscar()">
                    <la-icon [icon]="['las','search']" class="text-white"></la-icon>
                  </button>

                </div>
              </div>
            </div>
            <div class="form-group col-lg-6">
              <label for="cuenta"><span class="text-danger">*</span>Tipo Usuario:</label>
              <select class="form-control" formControlName="idTipoUsuario" [attr.disabled]="true"
                [(ngModel)]="model.idTipoUsuario">
                <option value="">--Seleccionar--</option>
                <option *ngFor="let item of TipoUsuarioLista" [ngValue]="item.idTipoUsuario">
                  {{ item.descripcion }}
                </option>
              </select>
            </div>
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="coordinacion">Coordinación:</label>
              <input class="form-control" type="text" formControlName="coordinacion" [readonly]="true"
                [(ngModel)]="model.coordinacion">
            </div>
            <div class="form-group col-lg-6">
              <label for="departamento">Departamento:</label>
              <input class="form-control" type="text" formControlName="departamento" [readonly]="true"
                [(ngModel)]="model.departamento">
            </div>
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-4">
              <label for="nombre">Nombre:</label>
              <input class="form-control" type="text" formControlName="nombre" [readonly]="true"
                [(ngModel)]="model.nombre">
            </div>
            <div class="form-group col-lg-4">
              <label for="apPat">Apellido Paterno:</label>
              <input class="form-control" type="text" formControlName="apPat" [readonly]="true"
                [(ngModel)]="model.apPat">
            </div>
            <div class="form-group col-lg-4">
              <label for="apMat">Apellido Materno:</label>
              <input class="form-control" type="text" formControlName="apMat" [readonly]="true"
                [(ngModel)]="model.apMat">
            </div>
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-4">
              <label for="estatus"><span class="text-danger">*</span>Estatus</label>
              <select class="form-control" formControlName="estatus" [(ngModel)]="model.estatus">
                <option [ngValue]="true">Activo</option>
                <option [ngValue]="false">Inactivo</option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label for="email"><span class="text-danger">*</span>Correo electrónico:</label>
              <input class="form-control" type="text" formControlName="email" [readonly]="false"
                [(ngModel)]="model.email">
              <div class="text-danger" *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.required">Este campo es requerido</div>
            </div>
            <div class="form-group col-lg-4" [hidden]="!isAdmin">
              <label for="rol"><span class="text-danger">*</span>Rol</label>
              <select class="form-control" formControlName="rol" [(ngModel)]="model.rol">
                <option *ngIf="model.rol == 1" [ngValue]="'1'">Administrador</option>
                <option [ngValue]="'2'">Administrador Suplente</option>
                <option [ngValue]="'3'">Docente</option>
                <option [ngValue]="'4'">Docente Externo</option>
              </select>
            </div>
          </div>

          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.dismiss()">Cerrar</button>&nbsp;
                <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!--Perfiles-->
<ng-template #cperfil let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Perfiles</div>
      <div class="card-body text-dark">

        <form [formGroup]="fperfil">
          <div class="row text-dark">
            <h5 class="title-subsection">Perfiles de sistema</h5>
            <div class="form-group col-lg-12">
              <ul class="interior" id="menu2">
                <li name="list" *ngFor="let Perfil of aPerfil; let a = index; let last = last">
                  <label for="{{ Perfil.id }}">
                    {{ Perfil.perfil }}
                  </label>
                  <ul class="interior">
                    <li name="list" *ngFor="let Modulo of Perfil.children; let b = index">
                      <label for="{{ Modulo.id }}">
                        {{ Modulo.modulo }}
                      </label>
                      <ul class="interior">
                        <li name="list" *ngFor="let Operacion of Modulo.children; let c = index">
                          <label for="{{ Operacion.id }}">
                            <input type="checkbox" name="opts" [checked]="(Operacion.acceso==1)?'checked':''"
                              id="{{ Operacion.id }}"> {{ Operacion.operacion }}
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.dismiss()">Cerrar</button>&nbsp;
                <button class="btn btn-danger" (click)="updIdPerfil()">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>