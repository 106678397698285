import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class ReservacionService {
  user = [];
  constructor(private http: HttpClient) {
  }
  ObtenerReservacionesPorFiltro(fechaInicio, fechaFin, idEstatus): Observable<any> {
    let data = {
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      idEstatus: idEstatus
    }
    return this.http.post(config.apiUrl + '/Reservacion/ObtenerReservacionesPorFiltro/', data);
  }

  getData() {
    //return this.http.get('assets/json/espacio.json');
    return this.http.get(config.apiUrl + '/Reservacion/');
  }

  getDataByUser(id: any) {
    //return this.http.get('assets/json/espacio.json');
    return this.http.get(config.apiUrl + '/Reservacion/reservacionporusuario/' + id);
  }

  reservacionPorUsuarioEspacioTipoEspacio(idUser: any, idEspacio: any) {
    //return this.http.get('assets/json/espacio.json');
    return this.http.get(config.apiUrl + '/Reservacion/ReservacionPorUsuarioEspacio/' + idUser + '/' + idEspacio);
  }

  getHorariosInicio(data: any): Observable<any> {
    //return this.http.get('assets/json/espacio.json');
    return this.http.post(config.apiUrl + '/Reservacion/HorariosInicio', data);
  }

  getHorariosFin(data: any): Observable<any> {
    //return this.http.get('assets/json/espacio.json');
    return this.http.post(config.apiUrl + '/Reservacion/HorariosFin', data);
  }

  cancelarReservacion(data: any): Observable<any> {
    //return this.http.get('assets/json/espacio.json');
    return this.http.post(config.apiUrl + '/Reservacion/CancelarReservacion', data);
  }

  autorizarReservacion(id: any): Observable<any> {
    //return this.http.get('assets/json/espacio.json');
    return this.http.post(config.apiUrl + '/Reservacion/AutorizarReservacion/' + id, null);
  }

  //Alta
  addData(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Reservacion/', data);
  }

  //Cambio
  updData(id: undefined, data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Reservacion/' + id, data);
  }



}
