import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styles: [
  ]
})
export class PerfilComponent implements OnInit {
  fprincipal: FormGroup;
  mprincipal;
  constructor(
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.iniciarForm();
  }

  Usuario;
  ngOnInit(): void {
    this.Usuario = this.usuarioService.getUser();
    console.log(this.Usuario);
    this.mprincipal = this.Usuario;
  }

  iniciarForm() {
    this.fprincipalIniciar();
  }

  fprincipalIniciar() {
    //Principal
    this.fprincipal = this.fb.group({
      idUsuario: [0],
      cuenta: [''],
      nombre: [''],
      apPat: [''],
      apMat: [''],
      email: [''],
      nombreInstancia: [''],
      activo: [true],
      token: [' '],
      rolUsuario: [''],
      idRolListado: ['0'],
      idRol: ['0'],
      nivel: [' '],
      departamento: [' ']
    });
    this.mprincipal = this.fprincipal;
  }

}
