import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { RHService } from 'src/app/data-access/rh.service';
import { GrupoService } from 'src/app/data-access/grupo.service';
import { TipoUsuarioService } from 'src/app/data-access/tipoUsuario.service'
import * as myGlobals from 'src/app/data-access/util';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/app/data-access/config';
import { EspacioService } from 'src/app/data-access/espacio.service';

@Component({
  selector: 'app-inactivo',
  templateUrl: './inactivo.component.html'
})
export class InactivoComponent implements OnInit {

  usuarioGrupo;
  usuarioTipo;
  usuarioSitio;
  //UsuariosLista;
  Usuario;
  UsuarioIcono;
  cuentasExterno;
  logo;
  bgapp;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private rhService: RHService,
    private grupoService: GrupoService,
    private tipoUsuarioService: TipoUsuarioService,    
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private recaptcha3: NgRecaptcha3Service,
    private http: HttpClient,
    private espacioService: EspacioService
  ) {
    localStorage.setItem('valido', '0');
    this.logo='ibero-090.svg';
    this.bgapp = 'bg-ibero-rojo';
  }

  ngOnInit() {
  }



}
