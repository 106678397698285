import { Component, OnInit, Input } from '@angular/core';
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import { DiaInhabilService } from 'src/app/data-access/diainhabil.service';
import * as util from 'src/app/data-access/util';
import Swal from 'sweetalert2';

import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';

export interface AutoCompleteModel {
  value: any;
  display: string;
}
interface Evento extends CalendarEvent {
  tipo: number;
  espacio: number;
}

@Component({
  selector: 'app-e-reservacion',
  templateUrl: './e-reservacion.component.html',
  styles: [
  ]
})
export class EReservacionComponent implements OnInit {
  @ViewChild('AvisoDePrivacidad') aviso: any;

  forma: FormGroup;
  model;
  NombreCompleto;
  p: number = 1;


  EspacioD = undefined;
  FechaD = undefined;
  HorarioD = undefined;
  HorarioFinD = undefined;

  CalMinDate;
  datePickerJson = {};
  markDisabled;
  Usuario;

  fechaSeleccionada: boolean = false; // Indica si se ha seleccionado una fecha

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private diaInhabilService: DiaInhabilService,
    private espacioTipoService: EspacioTipoService,
    private espacioService: EspacioService,
    private reservacionService: ReservacionService,
    private equipoService: EquipoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: NgbModal,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private router: Router
  ) {
    this.Usuario = this.usuarioService.getUser();
    //console.log(this.Usuario);
    const current = new Date();
    this.CalMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.addHorarioDia();
    let user = this.usuarioService.getUser();
    this.NombreCompleto = user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    this.iniciarForm();

    // let isAdmin = this.usuarioService.adm();
    // if (isAdmin) {
    //   this.router.navigate(['/menu-admin'])
    // }

    //this.MostrarAvisoDePrivacidadSiNoLoHaVistoYSiNoEsAdministrador();
  }

  MostrarAvisoDePrivacidadSiNoLoHaVistoYSiNoEsAdministrador() {
    let cuentaDeUsuarioLoggeado = this.usuarioService.getUser().cuenta;
    this.usuarioService.getDataById(this.usuarioService.getUser().idUsuario).toPromise().then(result => {
      let resultado: any = result;
      console.log(resultado);
      if (!resultado.aviso) {
        this.VerAvisoDePrivacidad();
      };
    });
  }

  VerAvisoDePrivacidad() {
    Swal.fire({
      //title: '<strong>Aviso de privacidad</strong>',
      title:'<div class="modal-dialog modal-lg" role="document"><div class="modal-content"><div class="modal-header text-center bg-ibero-rojo"><h5 class="modal-title text-white" id="exampleModalLabel">Aviso de privacidad</h5></div>',
      //icon: 'info',
      html:
        /*'Para ver nuestro aviso de privacidad, favor de ingresar <a href="../assets/IberoAvisoDePrivacidad2022.pdf">aquí</a>.'*/
        '<div class="modal-body"><div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="../assets/IberoAvisoDePrivacidad2022.pdf" allowfullscreen></iframe></div></div>',

      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Cerrar ',        
      confirmButtonAriaLabel: 'Thumbs up, great!',      
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down',
    }).then((result) => {
      let cuentaDeUsuarioLoggeado = this.usuarioService.getUser().cuenta;
      this.usuarioService.MarcarUsuarioDeQueYaVioAvisoDePrivacidad(cuentaDeUsuarioLoggeado);
    });
  }

  gHorario = [];
  addHorarioDia() {
    for (let i = 0; i <= 23; i++) {
      let h = (i < 10 ? '0' + i : i)
      //this.gHorario.push(h + ':00');
      this.gHorario.push({ i: i, hora: h + ':00' });

    }
  }

  ngOnInit(): void {
    this.addDiasInhabiles();
    this.addEspacio();
    this.addEquipo();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idReservacion: [0],
      idEspacio: [0, [Validators.required]],
      idUsuario: [0],
      dia: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      horaFin: ['', [Validators.required]],
      inicio: [''],
      fin: [''],
      idEstatus: [0],
      equipos: [''],
      equipo: [''],
      comentarios: ['']
    })
    this.model = this.forma;
  }

  json = { disable: undefined, disabledDates: undefined };
  DiaInhabil;
  DiaInhabilLista
  addDiasInhabiles() {
    this.spinner.show();
    this.diaInhabilService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.DiaInhabil = data;
        let disDates = [];
        for (let i = 0; i < this.DiaInhabil.length; i++) {
          let arrDia = this.DiaInhabil[i].dia.split('T')[0].split('-');
          disDates.push({ year: Number(arrDia[0]), month: Number(arrDia[1]), day: Number(arrDia[2]) });
        }
        this.json.disable = [6, 7];
        this.json.disabledDates = disDates;
        console.log(this.json);
        this.disableDays();
      });
  }

  Espacio;
  EspacioLista;
  addEspacio() {
    this.spinner.show();
    this.espacioService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        console.log(data);
        this.Espacio = data; 
        this.EspacioLista = this.Espacio;
        this.addLista();
      });
  }

  Equipo;
  EquipoLista;
  addEquipo() {
    this.spinner.show();
    this.equipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Equipo = data;
        //display: 'Lámpara', value: 1
        for (let i = 0; i < this.Equipo.length; i++) {
          this.Equipo[i]['display'] = this.Equipo[i].nombre;
          this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
        }
        this.EquipoLista = this.Equipo;
      });
  }

  Reservacion;
  ReservacionLista;
  addLista() {
    this.spinner.show();
    this.reservacionService.getDataByUser(this.Usuario.idUsuario).toPromise()
      .then(data => {
        this.spinner.hide();
        //console.log(data);
        this.Reservacion = data;
        this.ReservacionLista = this.Reservacion
        //console.log(this.ReservacionLista);
        for (let i = 0; i < this.ReservacionLista.length; i++) {
          console.log(this.ReservacionLista[i], this.Usuario.idUsuario, this.ReservacionLista[i]['idUsuario'], this.Usuario)
          const isOwner: boolean = this.Usuario.idUsuario === this.ReservacionLista[i]['idUsuario']
          const userRole: number = this.Usuario.roles ?? Number.MAX_VALUE
          const isAdmin: boolean = userRole <= 2
          this.ReservacionLista[i]['canBeEdited'] = isAdmin || isOwner
          this.ReservacionLista[i]['horaInicio'] = this.ReservacionLista[i].inicio.split('T')[1].substring(0, 5);
          this.ReservacionLista[i]['horaFin'] = this.ReservacionLista[i].fin.split('T')[1].substring(0, 5);
          const fecha = this.ReservacionLista[i].fin;
          const d1 = new Date();
          const d2 = new Date(fecha);
          this.ReservacionLista[i]["finalizada"] = false;
          if (d1.getTime() > d2.getTime()) {
            this.ReservacionLista[i]["finalizada"] = true;
            this.ReservacionLista[i].estatus = 'Realizada';
          }
        }

      });
  }

  restoreEspacioLista() {
    this.EspacioLista = this.Espacio;
  }

  HoraInicioLista;
  HoraFinLista;
  setEspacio() {
    this.model.dia = '';
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.FechaD = true;
    this.slideUpEquipos();
    if (this.model.idEspacio != 0 && this.model.idEspacio !== undefined) {
      this.FechaD = undefined;
    }
  }

  setHorario() {
    this.fechaSeleccionada = true;
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.slideUpEquipos();
    var dia = this.forma.controls["dia"].value;
    var diaSel = this.formatDate(dia);
    this.spinner.show();
    this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel }).subscribe(
      res => {
        this.HoraInicioLista = res;
        this.HorarioD = undefined;
        this.spinner.hide();
      },
      error => {
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );
  }

  setHoraFin() {
    this.model.horaFin = '';
    this.HorarioFinD = true;
    this.slideUpEquipos();
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.forma.controls["dia"].value;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.forma.controls["horaInicio"].value;
      this.spinner.show();
      this.reservacionService.getHorariosFin({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }
  }

  EquiposDisponibles;
  setEquipos() {
    if (this.model.horaFin != '' && this.model.horaFin !== undefined) {
      const dia = this.forma.controls["dia"].value;
      const diaSel = this.formatDate(dia);
      const horaInicio = this.forma.controls["horaInicio"].value;
      const horaFin = this.forma.controls["horaFin"].value;
      this.spinner.show();
      this.equipoService.equiposDisponibles({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio, HorarioFin: horaFin }).subscribe(
        res => {
          this.EquiposDisponibles = res.map(x => { x.cant = 0; return x });
          this.slideDownEquipos();
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    } else {
      this.slideUpEquipos();
    }
  }

  slideDownEquipos() {
    $('#equipoContent').slideDown({
      start: () => {
        $('#equipoContent').css({ display: 'flex' });
      }
    });
  }

  slideUpEquipos() {
    $('#equipoContent').slideUp(400, () => {
      this.EquiposDisponibles = [];
    });
  }

  minusEquipo(item) {
    if (item.cant > 0) {
      item.cant--;
    }
  }

  plusEquipo(item) {
    if (item.cant < item.cantidad) {
      item.cant++;
    }
  }

  hCancelar;
  nuevo(content) {
    this.iniciarForm();
    this.restoreEspacioLista();
    this.model.activo = true;
    this.hCancelar = true;
    this.bEdicion = undefined;
    this.FechaD = true;
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.modal.open(content, { size: 'xl' });
  }
  
  bEdicion = undefined;
  oTmp;
  editar(content, modelo) {
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    this.restoreEspacioLista();
    this.hCancelar = false;
    this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.oTmp = { ...modelo }; 
    //this.modal.open(content, { size: 'xl' });
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      var idx = this.ReservacionLista.findIndex(x => x.idReservacion == this.oTmp.idReservacion); 
      this.ReservacionLista[idx] = this.oTmp;       
      return true;  
    }}); 
    for (let e of modelo.equipos) {
      e.cant = e.cantidad;
    }
    this.EquiposDisponibles = modelo.equipos;
    this.slideDownEquipos();
  }

  guardar(idEstatus) {
    this.spinner.show();
    var dia = this.forma.controls["dia"].value;
    var hinicio = this.forma.controls["horaInicio"].value;
    var hfin = this.forma.controls["horaFin"].value;
    var idEspacio = this.forma.controls["idEspacio"].value;
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + 'T' + hinicio + ':00';
    var fin = dInicio + 'T' + hfin + ':00';
    this.forma.controls["inicio"].setValue(inicio);
    this.forma.controls["fin"].setValue(fin);
    this.forma.controls["idUsuario"].setValue(this.Usuario.idUsuario);

    //Si no es una cancelación, determinamos el estatus según el espacio
    if (idEstatus != 3) {
      let espacio = this.Espacio.find(x => x.idEspacio === idEspacio);
      if (espacio.autorizacion) { idEstatus = 1; }
    }
    this.forma.controls["idEstatus"].setValue(idEstatus);

    let arr = [];
    for (let e of this.EquiposDisponibles) {
      if (e.cant > 0) {
        e.cantidad = e.cant;
        arr.push(e);
      }
    }
    this.forma.controls["equipos"].setValue(arr);

    if (this.forma.controls["idReservacion"].value == undefined) {
      this.reservacionService.addData(this.forma.value).subscribe(
        res => {
          this.recargar();
          this.spinner.hide();
          this.toastr.success("La información ha sido enviada");
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        });
    }
    else {
      this.reservacionService.updData(this.forma.controls["idReservacion"].value, this.forma.value).subscribe(
        (res) => { this.toastr.success("La información ha sido actualizada"); this.recargar(); this.spinner.hide(); },
        (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
    }

  }

  cancelar() {
    Swal.fire({
      title: 'Cancelar Reservación',
      input: 'textarea',
      text: "Se cancelará la reservación. Déjanos un comentario con el motivo de la cancelación.",
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Cancelar Reservación',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {
        this.spinner.show();
        this.reservacionService.cancelarReservacion({ IdReservacion: this.model.idReservacion, MotivoCancelacion: comentario }).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success("La reservación ha sido cancelada"); this.recargar();
          },
          error => {
            if (typeof error.error === 'object') {
              this.toastr.error(error.message);
            } else {
              this.toastr.error(error.error);
            }
            this.spinner.hide();
          }
        );
        return true;
      }
    });
  }

  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  uf_Date(obj) { return util.uf_Date(obj); }
  formatDate(obj) { return util.formatDate(obj); }
  isToday(obj) { return util.isToday(obj); }

  util_fdt(fecha) {
    var d = new Date(fecha);
    return d.toLocaleString();
  }

  isDisabled;
  disableDays() {
    //to disable specific date and specific weekdays
    this.isDisabled = (
      date: NgbDateStruct
      //current: { day: number; month: number; year: number }
    ) => {
      return this.json.disabledDates.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
        || (this.json.disable.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
      )
        ? true
        : false;
    };
  }

  minDate: Date = new Date();
  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }


  onlyToday(date: Date): boolean {
    var d = new Date();
    d.setHours(0, 0, 0, 0);
    //console.log(date + ">=" + d)
    return date >= d;
  }
  /*
    isToday(dia){
      let d = new Date();
      let hoy = [
        d.getFullYear(),
        ('0' + d.getDate()).slice(-2),
        ('0' + (d.getMonth() + 1)).slice(-2)
      ].join('-');
  
      let fecha = [
        dia.getFullYear(),
        ('0' + dia.getDate()).slice(-2),
        ('0' + (dia.getMonth() + 1)).slice(-2)
      ].join('-');
      //alert(hoy+'=='+fecha);
      return (hoy==fecha);
    }
  */
}





