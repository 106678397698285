<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-section">Tipo Usuario</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
        <!--button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Agregar" (click)="nuevo(content)">
          <la-icon [icon]="['las','plus']" size="1x" class="text-white"></la-icon>
        </button-->
        <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
          <la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon>
        </button>
      </div>
    </div>

  <!--Filtros-->

  <!--Listado-->
  <!--
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-subsection">Datos del usuario</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
    </div>
  </div>
  -->
  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-4 text-center">Tipo Usuario</div>
    <div class="col-md-4 text-center">Tipo Espacio</div>
    <div class="col-md-2 text-center">Estatus</div>
    <div class="col-md-2 text-center">Acciones</div>
  </div>
  <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''" *ngFor="let item of UsuarioTipoLista; let i = index">
    <div class="col-md-4 text-center">{{ item.descripcion}}</div>
    <div class="col-md-4 text-center">{{ item.eLista }}</div>
    <div class="col-md-2 text-center">{{ item.activo?"Activo":"Inactivo" }}</div>
    <div class="col-md-2 text-center">
      <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
        <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
      </button>
    </div>
  </div>
  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (UsuarioTipoLista)?.length }} Registros</div>
  </div>

  <!--table class="table table-striped text-center table-fixed">
    <thead class="bg-secondary text-white">
      <tr>
        <th scope="col" class="encabezado orden">Nombre</th>
        <th scope="col" class="encabezado orden">Descripcion</th>
        <th scope="col" class="encabezado orden">Estatus</th>
        <th scope="col" class="encabezado orden">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr #item *ngFor="let item of RolLista; let i = index">
        <td class="w-10">{{ item.nombre }}</td>
        <td class="w-10">{{ item.descripcion }}</td>
        <td class="w-15">
          {{ item.estatus?"Activo":"Inactivo" }}
        </td>
        <td class="w-10">
          <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
            <la-icon [icon]="['las','edit']" class="text-dark"></la-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center bg-secondary text-white">
      <tr>
        <td colspan="10">{{ (RolLista)?.length }} Registros</td>
      </tr>
    </tfoot>
  </table-->

</div>

  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Datos del Tipo Usuario</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="idTipoUsuario"
                         [(ngModel)]="model.idTipoUsuario">
                </div>
                <div class="row text-dark">
                  <div class="form-group col-lg-6">
                    <label for="descripcion">Descripción:</label>
                    <input class="form-control" type="text" formControlName="descripcion"
                           [(ngModel)]="model.descripcion">
                           <div class="text-danger" *ngIf="forma.controls['descripcion'].touched && forma.controls['descripcion'].errors?.required">Este campo es requerido</div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="activo">Estatus</label>
                    <select class="form-control" formControlName="activo"
                            [(ngModel)]="model.activo">
                      <option value="">--Seleccionar--</option>
                      <option [ngValue]="true">Activo</option>
                      <option [ngValue]="false">Inactivo</option>
                    </select>
                    <div class="text-danger" *ngIf="forma.controls['activo'].touched && forma.controls['activo'].errors?.required">Este campo es requerido</div>
                  </div>

                  <div class="form-group col-lg-6">
                    <label for="espacio">Tipo de Espacios</label>
                    <tag-input [ngModel]="model.espacio" [onlyFromAutocomplete]="true" 
                    formControlName="espacio" placeholder="" secondaryPlaceholder="buscar"
                    [dragZone]="'zone1'">
                      <tag-input-dropdown 
                      [autocompleteItems]="EspacioTipoLista" 
                      [showDropdownIfEmpty]="true"
                      [dynamicUpdate]="false"
                      [focusFirstElement]="true"
                      displayBy="nombre"
                      identifyBy="idEspacioTipo" 
                      [appendToBody]="false">
                      </tag-input-dropdown>
                    </tag-input>  
                  </div>

                </div>
                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.dismiss()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

