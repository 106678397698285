<h5 class="text-center text-danger ">Panel administrador</h5>    
<div class="row align-items-center mt-2 bg-white text-ibero">       
  <div class="col-md-4 text-center mt-3" *ngFor="let item of GrupoLista">
      <button [class.selected]="selectedUser==item.idGrupo" class="btn icon-btn pt-2 pb-3 pl-1 pr-1" 
      (click)="filtra(content, item.idGrupo);onSelect(item.idGrupo)" ngbTooltip="">
          <!--img class="img-box p-3 item-image bg-white rounded animate__animated animate__backInDown" src="/assets/img/usr/{{ item.tipoId }}.svg" alt="" /-->
          <la-icon [icon]="item.icono" size="2x" class="img-box p-3 item-image bg-white rounded text-ibero"></la-icon>
      </button>
      <p class="item-text text-ibero" >{{ item.nombre }}</p>
  </div>
</div>