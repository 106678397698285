import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { GrupoService } from 'src/app/data-access/grupo.service';
//import { UsuarioService } from 'src/app/core/services/usuario.service';
import * as myGlobals from 'src/app/data-access/util';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/data-access/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    flogin: FormGroup | any;
    mlogin: FormGroup | any;
    /*enabled = false;
    loading = false;*/

    showLogin = true;

    notReady = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public fb: FormBuilder,
        private usuarioService: UsuarioService,
        private grupoService: GrupoService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {
        localStorage.setItem('valido', '0');
        //localStorage.setItem('valido', '1');
        this.iniciarForm();    
    }

    ngOnInit() {
        /*this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                this.spinner.show();
                this.showLogin = false;
                let routeData = data.state.root.firstChild.paramMap.get('id');

                if(routeData == null){
                    this.showLogin = true;
                    this.spinner.hide();
                } else {
                    this.usuarioService.validaCuenta({DataURL: routeData}).toPromise()
                    .then(data => {
                        setTimeout(() => {
                            this.spinner.hide();
                            if(data.idPerfil){
                                this.acceso(data);
                            }else{
                                this.toastr.warning(data.message);
                                this.showLogin = true;
                            }
                        }, 1000);
                    })
                }
            }
        });*/

        document.getElementById("cuenta")?.focus();
        //this.getRoles();
        this.iniciarForm();
    }

    iniciarForm() {
        this.flogin = this.fb.group({
            cuenta: ['', Validators.required],
            clave: ['', Validators.required],
            //idTipoUsuario: [0],
            //digito: ['']
            //idgrupo: ['']
            //,recaptcha: new FormControl('', Validators.required),
        });
        this.mlogin = this.flogin;
    }

  /*resolved(captchaResponse: string) {
    this.enabled = true;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }*/
  /*
  Roles;
  getRoles()
  {
    this.usuarioService.getRoles().toPromise()
      .then(data => {
        this.Roles = data;
        console.log(this.Roles);
      })
  }
  
  getChecks(){
    var choices = [];
    var els = document.getElementsByName('idRol');
    for (var i=0;i<els.length;i++){
      if ( els[i]['checked'] ) {
        choices.push(els[i].id);
      }
    }
    console.log(choices.toString());
    return choices.toString();
  }   
  */
    Cuenta:any;
    CuentaLista:any;
    Empleado;
    EmpleadoLista;
    cuenta;
    clave;
    idRol;

    entrar() {
        /*localStorage.setItem('valido', '1');
        this.router.navigate(['/e-bienvenida']);*/

        this.spinner.show();
        this.cuenta = this.flogin.controls["cuenta"].value;
        //return false;
        this.usuarioService.loginAccount(this.flogin.value).toPromise().then(data => {
            this.acceso(data);
        })
    }

    acceso(data){
        this.Cuenta = data;
        this.spinner.hide();
        console.log(this.Cuenta);
        //if (this.Cuenta.activo) {
        if (this.Cuenta.validoExterno) {
            //this.Cuenta.cuenta = this.cuenta;
            localStorage.clear();
            //this.Cuenta["grupoNombre"] = 'Estudiante';//grupo.nombre;
            this.usuarioService.addUser(this.Cuenta);
            localStorage.setItem('valido', '1');
            
            //this.Cuenta.perfiles[0].alumno.idPerfil = 1; // descomentar para ser admin
            localStorage.setItem('user', JSON.stringify(this.Cuenta));
            /*if(this.Cuenta.idRol==1){
            this.router.navigate(['/e-bienvenida']);
            }
            else{*/
            this.authService.verifyExpirationTime()
            let isAdmin = this.usuarioService.adm();
            (isAdmin ?  this.router.navigate(['/menu-admin']) :   this.router.navigate(['/menu']))
            //}
            
        }else{
            this.toastr.warning(this.Cuenta.message);
            return false;
        }
    }

    alphanumericOnly(e) {    
        var value =  e.target.value;
        //only allow a-z, A-Z, digits 0-9 and comma, with only 1 consecutive comma ...
        if (!e.key.match(/[a-zA-Z0-9]/)) {
            e.preventDefault();  
        }
    }

    turnOn(){
        this.notReady = false;
    }

}
