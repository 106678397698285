import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { TipoUsuarioService } from 'src/app/data-access/tipoUsuario.service'
import { RolService } from 'src/app/data-access/rol.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styles: [
  ]
})
export class UsuarioComponent implements OnInit {
  forma: FormGroup;
  model;
  p: number = 1;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private tipoUsuarioService: TipoUsuarioService,
    private rolService: RolService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    private _sanitizer: DomSanitizer
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.iniciarForm();
  }

  sUsuario;
  ngOnInit(): void {
    this.addTipoUsuario();
    this.addRol();
    this.sUsuario = this.usuarioService.getUser();
    //console.log(this.sUsuario);
    this.isAdmin = this.sUsuario.roles.split(',').includes("1") ? true : false;
    //alert(this.isAdmin);
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idUsuario: [0],
      cuenta: [''],
      digito: [''],
      nombre: [''],
      apPat: [''],
      apMat: [''],
      email: ['', [Validators.required]],
      estatus: [true, [Validators.required]],
      token: [' '],
      rol: ['', [Validators.required]],
      roles: [''],
      idTipoUsuario: [0],
      claveCoordinacion: [' '],
      coordinacion: [' '],
      claveDepartamento: [' '],
      departamento: [' ']
    });
    this.model = this.forma;
  }

  TipoUsuarioLista;
  TipoUsuario;
  addTipoUsuario() {
    this.spinner.show();
    this.tipoUsuarioService.getData().toPromise()
      .then(data => {
        this.TipoUsuario = data;
        //console.log(this.TipoUsuario);
        this.TipoUsuarioLista = this.TipoUsuario;
        this.spinner.hide();
      })
  }

  RolLista;
  Rol;
  addRol() {
    this.spinner.show();
    this.rolService.getData().toPromise()
      .then(data => {
        this.Rol = data;
        for (let i = 0; i < this.Rol.length; i++) {
          this.Rol[i]['display'] = this.Rol[i].nombre;
          this.Rol[i]['value'] = this.Rol[i].idRol;
        }
        //console.log(this.Rol);
        this.RolLista = this.Rol;
        this.spinner.hide();
        this.addLista();
      })
  }

  Usuario;
  UsuarioLista;
  addLista() {
    this.spinner.show();
    this.usuarioService.getUsuarioListadoSistema().toPromise()
      .then(data => {
        this.Usuario = data;

        this.Usuario.forEach(i => {
          i.nombreCompleto = i.nombre + ' ' + i.apPat + ' ' + i.apMat;
          if (i.roles != '' && i.roles != undefined) {
            var arrLista = this.RolLista
              .filter(x => i.roles.split(',').includes(x.idRol.toString()))
              .map(function (item) { return item.nombre; });
            i['eLista'] = arrLista.join(', ');
          }
        });
        //Asignamos el universo de items al listado, mediante una entidad de filtro
        this.UsuarioLista = this.Usuario;
        this.spinner.hide();
      })
  }

  Evento_Buscar() {
    var resultado = this.usuarioService.ObtenerInformacionAdicionalDelUsuario(this.forma.controls["cuenta"].value, 24)
      .toPromise().then(result => {

        if (result == null) {
          this.toastr.error("No se pudo encontrar el usuario.");
        } else {
          let resultado: any = result;
          this.forma.controls["idUsuario"].setValue(resultado.idUsuario);
          this.forma.controls["cuenta"].setValue(resultado.cuenta);
          this.forma.controls["digito"].setValue(resultado.digito);
          this.forma.controls["nombre"].setValue(resultado.nombre);
          this.forma.controls["apPat"].setValue(resultado.apPat);
          this.forma.controls["apMat"].setValue(resultado.apMat);
          this.forma.controls["email"].setValue(resultado.email);
          this.forma.controls["estatus"].setValue(resultado.estatus);
          this.forma.controls["roles"].setValue(resultado.roles);
          this.forma.controls["idTipoUsuario"].setValue(resultado.idTipoUsuario);
          this.forma.controls["claveCoordinacion"].setValue(resultado.claveCoordinacion);
          this.forma.controls["coordinacion"].setValue(resultado.coordinacion);
          this.forma.controls["claveDepartamento"].setValue(resultado.claveDepartamento);
          this.forma.controls["departamento"].setValue(resultado.departamento);
        }


      });
  }

  addFiltros() {
    //this.loadRoles();
    //this.loadInstancias();
  }

  nuevo(content) {
    this.iniciarForm();
    this.modal.open(content, { size: 'xl' });
  }

  isAdmin;
  oTmp;
  editar(content, modelo) {
    if (modelo.roles != null && modelo.roles != "") {
      modelo.rol = modelo.roles;
      //this.isAdmin = modelo.rol == "1" ? true : false;
    }
    this.model = modelo;
    //console.log(this.model);
    //this.modal.open(content, { size: 'xl' });
    this.oTmp = { ...modelo }; 
    this.modal.open(content, { size: 'xl', beforeDismiss: () => {  
      var idx = this.UsuarioLista.findIndex(x => x.idUsuario == this.oTmp.idUsuario); 
      this.UsuarioLista[idx] = this.oTmp;       
      return true;  
    }}); 
  }

  guardar() {
    this.spinner.show();
    let rol = this.forma.controls["rol"].value;
    this.forma.controls["roles"].setValue(rol);

    if (!this.FormularioEsValido(this.forma, this.toastr)) {
      return;
    }


    if (this.forma.controls["idUsuario"].value == undefined) {
      this.usuarioService.addData(this.forma.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
    else {
      this.usuarioService.updData(this.forma.controls["idUsuario"].value, this.forma.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
  }
  FormularioEsValido(forma: FormGroup, toastr: ToastrService): boolean {

    console.log(this.forma.controls["estatus"]);
    console.log(this.forma.controls["roles"]);
    return true;

  }

  recargar() {
    this.spinner.hide();
    this.modal.dismissAll();
    this.addLista();
  }

}
