import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';


@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {

  columnDefs: ColDef[] = [
    { field: 'idReservacion', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'nombreEspacio', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'fechaHorarioInicial', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'fechaHorarioFinal', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'capacidadEspacio', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'maximoEspacio', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'estatusReservacion', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'cuentaUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'nombreUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'apPatUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'apMatUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'emailUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'claveDepartamentoUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'departamentoUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'claveCoordinacionUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'coordinacionUsuario', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'comentariosReservacion', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
    { field: 'motivoCancelacionReservacion', sortable: true, filter: true, filterParams: { buttons: ['reset'] } },
  ];
  fechaDeInicio: Date;
  fechaFin: Date;

  rowData = [];

  private gridApi!: GridApi;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private toastr: ToastrService
    ) {
      if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    this.InicializarFechasFiltroConFechasDefault();
  }

  InicializarFechasFiltroConFechasDefault() {
    //this.fechaDeInicio = new Date();
    //this.fechaFin = new Date();

    /*this.fechaDeInicio.setDate(new Date().getDate() - 1);
    this.fechaFin.setDate(new Date().getDate());*/
  }

  ngOnInit(): void {

  }

  Evento_Buscar() {
    let esValidoElFormulario = this.ValidarPeriodoDeFechas();

    if (esValidoElFormulario) {
      this.usuarioService.SearchAllReservationReportInDelimitingTimePeriod(this.fechaDeInicio, this.fechaFin)
        .toPromise().then(result => {
          this.rowData = result;
          console.log("return");
          console.log(this.rowData);
        });
    }
  }

  ValidarPeriodoDeFechas(): boolean {
    let formularioValido: boolean = true;

    if (!(this.fechaDeInicio instanceof Date)) {
      this.toastr.error("Debe seleccionar una fecha de inicio.");
      formularioValido = false;
    }

    if (!(this.fechaFin instanceof Date)) {
      this.toastr.error("Debe seleccionar una fecha fin.");
      formularioValido = false;
    }

    if (this.fechaDeInicio > this.fechaFin) {
      this.toastr.error("Las fecha de inicio debe ser menor a la fecha fin.");
      formularioValido = false;
    }

    return formularioValido;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    console.log(this.gridApi);
  }

  Evento_ExportarAExcel() {
    console.log(this.gridApi);
    this.gridApi.exportDataAsCsv();
  }

}
