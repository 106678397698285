import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class RolService {
  user = [];
  constructor(private http: HttpClient) {
  }

    getData() {
      //return this.http.get('assets/json/equipo.json');
      return this.http.get(config.apiUrl+'/Rol');
    }

    //Alta
    addData(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Rol/', data);
    }

    //Cambio
    updData(id:undefined, data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Rol/' + id, data);
    } 

}
