import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptureModule } from 'ngx-capture';

import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import { lasSave, lasEllipsisH, 
  lasUsers, lasUserShield, lasUserCog, lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
  lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe, 
  lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher, lasBriefcase, lasEnvelope, lasAddressBook, 
  lasMoneyCheckAlt, lasQuestion, lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, 
  lasTools, lasUser, lasEdit, lasPlus, lasMinus, lasAngleRight, lasAngleLeft, lasList, lasCalendar, lasCalendarPlus, lasArrowLeft, lasUserTag, lasBookOpen, lasTag, lasHeadphones, lasArchive
 } from 'angular-line-awesome/icons';

 import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import {ECalendarioComponent} from './e-calendario/e-calendario.component';
import {EReservacionComponent} from './e-reservacion/e-reservacion.component';
import {EHistoricoComponent} from './e-historico/e-historico.component';
import { MenuComponent } from './menu/menu.component';
import { NgxPaginationModule } from 'ngx-pagination';


import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
  declarations: [PerfilUsuarioComponent, ECalendarioComponent, EReservacionComponent, EHistoricoComponent, MenuComponent],
  imports: [
    CommonModule,
    TagInputModule, 
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AngularLineawesomeModule,
    NgxQRCodeModule,
    NgxCaptureModule,
    NgxPaginationModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }],
})
export class EstudianteModule {
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSave, lasEllipsisH, 
      lasUsers, lasUserShield, lasUserCog, 
      lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
      lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
      lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher,
      lasBriefcase, lasEnvelope, lasAddressBook,
      lasMoneyCheckAlt, lasQuestion, lasShieldAlt,
      lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasTools,
      lasUser, lasUserShield, lasEdit, lasPlus, lasAngleRight, lasAngleLeft, lasList, 
      lasCalendar, lasCalendarPlus, lasArrowLeft, lasUserTag, lasBookOpen, lasTag, lasHeadphones,
      lasArchive, lasMinus
    ]);
  }
}
