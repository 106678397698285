import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../data-access/config';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  SearchAllReservationReportInDelimitingTimePeriod(fechaDeInicio: Date, fechaFin: Date): Observable<any> {
    let data = {
      fechaInicio: fechaDeInicio,
      fechaFin: fechaFin
    };
    return this.http.post(config.apiUrl + '/Usuario/SearchAllReservationReportInDelimitingTimePeriod/', data);
  }
  GetAllReservationReport(): Observable<any> {
    return this.http.get(config.apiUrl + '/Usuario/GetAllReservationReport/');
  }
  MarcarUsuarioDeQueYaVioAvisoDePrivacidad(cuenta: any) {
    this.http.get(config.apiUrl + '/Usuario/RegistrarUsuarioYaVioAvisoDePrivacidad/' + cuenta).toPromise().then(result => { });
  }
  VerificarSiElUsuarioEsElCreadorDelEventoADesplegar(idReservacion: any, idUsuarioLoggeado: any) {
    return this.http.get(config.apiUrl + '/usuario/VerificarSiElUsuarioEsElCreadorDelEventoADesplegar/' + idReservacion + '/' + idUsuarioLoggeado);
  }
  user = [];
  public nombreCompleto$ = new BehaviorSubject<String>("")
  constructor(private http: HttpClient) {
  }
  /*
    getTipoUsuario() {
      return this.http.get(config.apiUrl+'/Usuario_tipo');
    }
    */

  getPerfil() {
    return this.http.get(config.apiUrl + '/perfil');
  }

  getRol() {
    return this.http.get(config.apiUrl + '/rol');
  }

  getRolListado() {
    return this.http.get(config.apiUrl + '/usuario/getrolfull/0');
  }
  /*
  getUsuarioAll() {
    return this.http.get(config.apiUrl + '/Usuario');
  }
  getUsuarioById(id:undefined,) {
    return this.http.get(config.apiUrl + '/usuario/' + id);
  }
  */
  getDataById(id) {
    return this.http.get(config.apiUrl + '/usuario/' + id);
  }

  ObtenerInformacionAdicionalDelUsuario(cuenta, idTipoDeUsuario) {
    return this.http.get(config.apiUrl + '/Usuario/ObtenerInformacionUsuario/' + cuenta + '/' + idTipoDeUsuario);
  }

  //Consulta
  getData() {
    return this.http.get(config.apiUrl + '/Usuario');
  }

  //Consulta Listado Usuarios Sistema
  getUsuarioListadoSistema() {
    return this.http.get(config.apiUrl + '/Usuario/');
    //return this.http.get('assets/json/usuario.json');
  }

  //Consulta Listado Usuarios Sistema
  getEncuestaListado() {
    //return this.http.get(config.apiUrl + '/Usuario/GetListadoSistema');
    return this.http.get(config.apiUrl + '/encuesta.json');
  }

  //Consulta Listado Participantes
  getUsuarioListadoParticipante(instancia: undefined) {
    return this.http.get(config.apiUrl + '/Usuario/GetListadoSistema/' + instancia);
  }

  //Consulta Listado Participantes
  getRoles() {
    return this.http.get('assets/json/rol.json');
    //return this.http.get(config.apiUrl + '/Rol/');
  }

  //Alta
  addData(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Usuario/', data);
  }

  //Cambio
  updData(id: undefined, data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Usuario/' + id, data);
  }

  //Login
  login(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Usuario/UserAccount/', data);
  }

  //Login Cuenta
  /*
  loginAccount(cuenta): Observable<any> {
    return this.http.get(config.apiUrl + '/Usuario/LoginCuenta/' + cuenta);
  }
  */

  //Login Cuenta
  loginAccount(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Usuario/LoginCuenta/', data);
  }

  validaCuenta(data): Observable<any> {
    //alert(data);
    return this.http.post(config.apiUrl + '/Usuario/ValidaCuenta/', data);
  }

  //Login
  loginSHA(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Usuario/login/', data);
  }

  //Restaura
  restaura(id: undefined, data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Usuario/Restaura/' + id, data);
  }

  //Restaura
  recupera(data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Usuario/PutSendRandom', data);
  }

  //Alta
  addResponsableColegio(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Colegio_responsable/', data);
  }

  //Perfiles
  getPerfiles() {
    return this.http.get(config.apiUrl + '/Usuario/GetPerfiles');
  }

  //Consulta
  getResponsableColegio(id: undefined) {
    return this.http.get(config.apiUrl + '/Colegio_responsable/GetbyColegio/' + id);
  }

  //Consulta
  getColegioListado() {
    return this.http.get(config.apiUrl + '/Colegio/GetActive');
  }

  //Consulta
  getColegioAll() {
    return this.http.get(config.apiUrl + '/Colegio');
  }

  //Consulta
  getColegio(id: undefined) {
    return this.http.get(config.apiUrl + '/Colegio/' + id);
  }

  //Agergar
  addColegio(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Colegio/', data);
  }

  //Cambio
  updColegio(id: undefined, data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Colegio/' + id, data);
  }

  //RolesPermisos
  updRolPermisos(data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/ModuloPermiso/PutPermisos/', data);
  }

  addUser(usuario) {
    this.nombreCompleto$.next(usuario.nombre + ' ' + usuario.apPat + ' ' + usuario.apMat);
    localStorage.setItem('user', JSON.stringify(usuario));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user') || '{}') || [];
  }

  addVisita(usuario) {
    localStorage.setItem('visita', JSON.stringify(usuario));
  }

  getVisita() {
    return JSON.parse(localStorage.getItem('visita') || '{}') || [];
  }



  getUsuarioAll() {
    return this.http.get(config.apiUrl + '/Usuario');
  }

  getDatosEmpleado() {
    return this.http.get('assets/json/datosEmpleado.json');
  }

  getSitio() {
    return config.plantel;
  }

  es() {
    return true;
    //let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    //return (usr.idRol === 3)
    //return (usr.usuario_tipoId === 2)
  }

  ex() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.usuario_tipoId === 2)
  }

  in() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.usuario_tipoId === 1)
  }

  adm() {

    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    //console.log("Entered is admin verification process..");
    //console.log(usr);


    try {
      let roles = usr.roles.split(",");
      //console.log("-----------------");
      return (roles.includes("1") || roles.includes("2"));
    } catch (e) {
      //console.log("Roles is not splitable.");
      //console.log("-----------------");
      return false;

    }

  }

  ser() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.perfilId === 3)
  }

  grd() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return usr.tipo == "103";
    //return (usr.usuario_tipoId === 2)
  }

  anony() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    //console.log(usr);
    return usr.tipo == undefined;
  }

  usr() {
    return 'Nombre';
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return usr.nombre + ' ' + usr.apPat;
  }

}
