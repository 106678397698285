import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class EquipoService {
  user = [];
  constructor(private http: HttpClient) {
  }

    getData() {
      //return this.http.get('assets/json/equipo.json');
      return this.http.get(config.apiUrl+'/Equipo');
    }

    //Alta
    addData(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Equipo/', data);
    }

    //Cambio
    updData(id:undefined, data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Equipo/' + id, data);
    }
    
    equiposDisponibles(data:any): Observable<any> {
        return this.http.post(config.apiUrl + '/Equipo/EquiposDisponibles', data);
    }

}
